import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Header from "../components/product/Header";
import Description from "../components/product/Description";
import Parents from "../components/product/Parents";
import Comments from "../components/product/Comments";
import Actions from "../components/product/Actions";
import ShowModal from "../components/product/ShowModal";
import Diet from "../components/product/Diet";
import { setLoading, setRefresh } from "../reducers/loading";
import NotFound from "../pages/NotFound";

const Show = () => {
  const branch = useSelector((state) => state.branch.branch);
  const { user } = useSelector((state) => state.user);
  const [alreadyTranslated, setAlreadyTranslated] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const dishes = useSelector((state) => state.dishes.dishes);
  const { id } = useParams();
  const [waiterDish, setWaiterDish] = useState({});
  const [online, setOnline] = useState(true);
  const orderInitialState = {
    id: Number(id),
    image: "",
    name: "",
    quantity: 1,
    comment: "",
    totalAmount: 0,
    totalItemAmount: 0,
    discountPercent: 0,
    items: [],
  };
  const [product, setProduct] = useState();
  const [order, setOrder] = useState(orderInitialState);
  const { prefix } = branch;
  const setting = branch?.setting || product?.setting;

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setRefresh(false));
    const dish = dishes.find((d) => d.id === Number(id));
    if (dish) {
      setProduct(dish);
    }
    dispatch(setLoading(false));
  }, [branch, id, dishes]);

  if (
    branch.id === null ||
    branch?.prefix === "P" ||
    branch?.prefix === undefined
  ) {
    return <NotFound />;
  }

  if (!product) {
    return <Grid />;
  }

  window.addEventListener("offline", (event) => {
    setOnline(true);
  });

  window.addEventListener("online", (event) => {
    setOnline(false);
  });

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const fetchWaiterDish = () => {
    const token = user.accessToken;
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      ApiKey: process.env.REACT_APP_BACKOFFICE_API_KEY,
    };
    fetch(
      `${process.env.REACT_APP_REQUEST_BASE_URL}/dishes/${product.id}/waiter_dish?view=normal`,
      {
        method: "get",
        headers: new Headers(headers),
      },
    )
      .then((res) => res.json())
      .then((dish) => {
        setWaiterDish(dish);
        setAlreadyTranslated(true);
      })
      .catch((err) => console.log(err));
  };

  if (window.screen.width >= 1344) {
    return (
      <ShowModal
        product={product}
        branch={branch}
        setting={setting}
        online={online}
        order={order}
        setOrder={setOrder}
      />
    );
  }

  return (
    <Grid container justifyContent="space-between" className="show-container">
      <Grid item xs={12} className="show-header-container">
        <Header branch={branch} product={product} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          height: product?.image ? "60vh" : "100vh",
          marginTop: product?.image ? "-55px" : "0px",
          borderTopLeftRadius: product?.image ? "40px" : "0px",
          borderTopRightRadius: product?.image ? "40px" : "0px",
          backgroundImage: `url(${setting?.background_general_image})`,
        }}
        className="show-ul"
      >
        <List className="show-ul-inside">
          <Description
            product={product}
            branch={branch}
            waiterDish={waiterDish}
          />
          <Divider />
          {product?.allergens?.length > 0 && (
            <Diet
              setting={setting}
              product={product}
              online={online}
              waiterAllergens={waiterDish.dish_allergens}
            />
          )}
          <Parents
            parentsRelationship={product.accompaniments}
            waiterRelationship={waiterDish.dish_accompaniments}
            waiterParentKey={"accompaniment"}
            order={order}
            branch={branch}
          />
          <Parents
            parentsRelationship={product.toppings}
            waiterRelationship={waiterDish.dish_toppings}
            waiterParentKey={"topping"}
            order={order}
            setOrder={setOrder}
            branch={branch}
          />
          <Parents
            parentsRelationship={product.variants}
            waiterRelationship={waiterDish.dish_variants}
            waiterParentKey={"variant"}
            order={order}
            setOrder={setOrder}
            branch={branch}
          />
          {prefix === "T" && (
            <Comments text={order.comment} setText={setOrder} order={order} />
          )}
        </List>
        <Grid
          style={{
            display: alreadyTranslated ? "none" : "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => fetchWaiterDish()}
            style={{
              position: "fixed",
              bottom: "0",
              width: "80%",
              marginBottom: '25px',
              display:
                i18n.language !== branch?.primary_language ? "flex" : "none",
              background:
                setting?.background_color_main_button ||
                "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
              fontFamily: setting?.general_font,
              color: setting?.text_color_main_button || "white",
            }}
          >
            {t("product.waiterButton")}
          </Button>
        </Grid>
      </Grid>
      {prefix === "T" && (
        <Grid item>
          <Actions product={product} order={order} setOrder={setOrder} />
        </Grid>
      )}
    </Grid>
  );
};

export default Show;
