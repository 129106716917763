import React from "react";
import { Grid } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import BadgeOrders from "../utils/BadgeOrders";

const useStyles = makeStyles(() => ({
  headerBackArrow: {},
  header: {
    marginTop: "3vh",
    position: "absolute",
    margin: "20px",
    zIndex: 100000,
  },
}));

const Header = ({ setting }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Grid
      item
      className={classes.header}
      onClick={() => navigate(-1, { replace: true })}
    >
      <ArrowBack
        className={classes.headerBackArrow}
        sx={{
          color: setting?.background_color_main_button || "black",
        }}
      />
    </Grid>
  );
};

export default Header;
