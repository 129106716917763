import axios from "axios";
import { getModelViewerUrl } from "./firebase";
import { setLoading } from "../reducers/loading";
import { addDish, setSelectedDish, setDishes } from "../reducers/dishes.js";
import { setBranch } from "../reducers/branch";
import { setFilters } from "../reducers/filter";

export const fetchBranch = ({
  branchInitValues,
  urlName,
  dispatch,
  filters,
  currentLanguage,
}) => {
  dispatch(setLoading(true));
  axios
    .get(`branches?url_name=${urlName}&lang=${currentLanguage}`)
    .then((res) => {
      if (res) {
        const menusCategories = [];
        const responseBranch = res.data;
        const newBranch = { ...branchInitValues, ...responseBranch };
        if (responseBranch) {
          if (responseBranch.menu.categories.length > 0) {
            responseBranch.menu.categories.forEach((category) => {
              if (category.dishes.length > 0) {
                menusCategories.push(category);
              }
            });
          }
          dispatch(setDishes(menusCategories.flatMap((c) => c.dishes)));
          newBranch.categories = menusCategories;
          dispatch(setBranch(newBranch));
          if (filters) {
            dispatch(setFilters({ ...filters }));
          }
          menusCategories.forEach((c) => {
            c.dishes.forEach(async (d) => {
              const url = await getModelViewerUrl(d.url_3d_web);
              if (url) {
                dispatch(addDish({ ...d, url_3d_web: url }));
              }
            });
          });
        }
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
      dispatch(setSelectedDish({}));
    });
};

export const fetchProduct = ({
  id,
  dispatch,
  setSelectedDish,
  currentLanguage,
  urlName,
}) => {
  dispatch(setLoading(true));
  axios
    .get(`products/${id}?url_name=${urlName}&lang=${currentLanguage}`)
    .then(async (res) => {
      if (res) {
        const dish = res.data;
        dispatch(setSelectedDish({ ...dish, urlName }));
        const url = await getModelViewerUrl(dish.url_3d_web);
        if (url) {
          document.body.style.overflow = "hidden";
          dispatch(setSelectedDish({ ...dish, url_3d_web: url }));
        }
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
      dispatch(
        setBranch({
          id: null,
          tableId: null,
          restaurantId: null,
          allergens: [],
          categories: [],
          orders: [],
          orderedOrders: [],
        }),
      );
    });
};
