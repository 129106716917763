import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ProductPlaceholder from '../../../images/productPlaceholder.svg';
import { closeOrder } from '../../../reducers/info';

const Orders = ({classes, orders, changeAmount, setIdToDelete, orderType}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const splitPath = location.pathname.split('/');
  const TOKEN = 1;
  const TOKEN_VALUE = 2;
  const basePath = splitPath[TOKEN] + '/' + splitPath[TOKEN_VALUE];

  return orders.map((order, index) => (
    <Grid key={order.id} sx={{ overflow: 'auto' }} >
      <ListItem>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Grid container spacing={2} component={Link} to={`${basePath}/${order.id}`} style={{ textDecoration: 'none'}} onClick={() => dispatch(closeOrder())}>
              <Grid item>
                <img
                  src={order.image ? order.image : ProductPlaceholder}
                  alt="product"
                  style={{ height: '11vh'}}
                />
              </Grid>
              <Grid item>
                <Grid container direction='column' spacing={1}>
                  <Grid item className={classes.productTitle}>
                    {order.name}
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item className={classes.finalPrice}>
                        {order.discountPercent ? `$${Number(order.totalAmount * ((100 - order.discountPercent)/100)).toFixed(2)}` : `$${Number(order.totalAmount).toFixed(2)}`}
                      </Grid>
                      <Grid item className={classes.originalPrice}>
                        {order.discountPercent && `$${Number(order.totalAmount).toFixed(2)}`}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {order.discountPercent &&
                    <Chip
                      label={order.discountPercent ? `-${order.discountPercent}%` : ''}
                      sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        height: '3vh !important',
                        background: 'linear-gradient(180deg, #F4B430 0%, #FFC800 100%)',
                        borderRadius: '60px 30px 30px 0px',
                      }}
                    />
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" direction="column">
              <Grid item sx={{ marginTop: '2vh' }}>
                <Chip
                  icon={orderType === "orders" ? <RemoveIcon style={{color: '#ff4b55'}} onClick={() => changeAmount(order, -1)}/>: <></>}
                  deleteIcon={orderType === "orders" ? <AddIcon style={{color: '#ff4b55'}}/> : <></>}
                  onDelete={() => changeAmount(order, 1)}
                  label={order ? order.quantity.toString() : "0"}
                  className={classes.chipAccompaniment}
                />
              </Grid>
              <Grid item>
                { orderType === "orders" && <DeleteIcon sx={{ color: 'red', fontSize: 20 }} onClick={() => setIdToDelete(order.id)}/>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
    </Grid>
  ))
}

export default Orders;
