import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { CircleFlag } from "react-circle-flags";
import {
  Alert,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForward from "@mui/icons-material/ArrowForwardIos";
import ArrowDown from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import filter from "../images/filter.svg";
import Categories from "../components/menu/categories/Categories";
import {
  setDisableWaiterModal,
  setShowSnackbar,
  setLastDishScrollPosition,
  setOpenSidebar,
  setViewFromIndex,
} from "../reducers/info";
import Filter from "./Filter";
import OrderButton from "../components/menu/OrderButton";
import Sidebar from "../components/utils/Sidebar";
import NotFound from "../pages/NotFound";
import { codeFlagsLanguage } from "../services/languages";
import "../styles/index.css";

const Index = () => {
  const { filterOpen } = useSelector((state) => state.filter.filter);
  const { showSnackbar, order, lastDishScrollPosition } = useSelector(
    (state) => state.info,
  );
  const branch = useSelector((state) => state.branch.branch);
  const location = useLocation();
  const { setting } = branch;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { t, i18n } = useTranslation();
  const { isLoading } = useSelector((state) => state.loading);
  const checked = order.open;
  const [canView3D, setCanView3D] = useState(false);
  const [firstDish3D, setFirstDish3D] = useState({});
  const currentAmount = branch?.orders
    ? branch.orders.reduce((acc, o) => {
        const amount = o.discountPercent
          ? o.totalAmount * ((100 - o.discountPercent) / 100)
          : o.totalAmount;
        return acc + amount;
      }, 0)
    : 0;

  useEffect(() => {
    const div = document.getElementById("dishContent");
    if (div) {
      div.scrollTo(0, lastDishScrollPosition);
    }
  }, []);

  useEffect(() => {
    let dish = {};
    if (branch?.categories?.length > 0) {
      branch.categories.forEach((c) => {
        c.dishes.forEach((d) => {
          if (dish.id === undefined && d.url_3d_web) {
            dish = d;
            setFirstDish3D(d);
            setCanView3D(true);
          }
        });
      });
    }
  }, [branch]);

  if (filterOpen) {
    return <Filter />;
  }

  const languages = codeFlagsLanguage(t);
  const countryCode = languages.find(
    (hash) => hash.code === i18n.language,
  ).flag;

  return (
    <Grid
      sx={{
        overflow: "hidden !important",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "round",
        backgroundImage: `url(${branch?.setting?.background_general_image})`,
      }}
    >
      <Sidebar tableId={branch?.table_id} />
      <Grid container sx={{ overflow: checked ? "hidden !important" : "auto" }}>
        <Grid item className="header" sx={{ zIndex: checked ? -1 : "auto" }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={1} className="countryFlag">
              <IconButton
                sx={{ marginLeft: "1vw", color: "gray" }}
                onClick={() => {
                  window.history.pushState(null, null, null);
                  dispatch(setOpenSidebar(true));
                }}
              >
                <CircleFlag countryCode={countryCode} height="30" />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={window.screen.width >= 1344 ? 9 : 7}
              className="textField"
              sx={{ fontFamily: branch.setting?.general_font }}
            >
              <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={t("index.searchPlaceholder")}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon className="searchIcon" />
                    </InputAdornment>
                  ),
                  className: "search",
                  style: {
                    fontFamily: setting?.general_font,
                  },
                }}
              />
            </Grid>
            <Grid item xs={window.screen.width >= 1344 ? 2 : 4}>
              <Button
                height="5vh"
                className="filter"
                onClick={() => navigate("filters")}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid
                    item
                    className="filterText"
                    sx={{ fontFamily: branch.setting?.general_font }}
                    xs={8}
                  >
                    {t("index.filter")}
                  </Grid>
                  <Grid item className="filterIcon" xs={2}>
                    <img src={filter} alt="filter" width="12px" height="12px" />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={5}
          className="subHeader"
        >
          <Grid item xs={4}>
            {branch?.image && (
              <img src={branch?.image} alt="tea" className="restaurantLogo" />
            )}
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ visibility: canView3D ? "visible" : "hidden" }}
            onClick={() => dispatch(setViewFromIndex(true))}
          >
            <Chip
              label={t("index.viewMenu3D")}
              className="menu3d"
              sx={{
                color: `${
                  setting?.text_color_main_button || "white"
                } !important`,
                fontFamily: branch.setting?.general_font,
                background: `${
                  setting?.background_color_main_button ||
                  "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)"
                } !important`,
              }}
              onClick={() => navigate(`${firstDish3D.id}/product_viewer`)}
            />
          </Grid>
          <Grid item xs={4} style={{ marginLeft: "-20px" }}>
            <Link
              to={`${location.pathname}/about`}
              style={{ textDecoration: "none" }}
            >
              <Button style={{ textTransform: "none", width: "30vw" }}>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  className="infoContainer"
                >
                  <Grid item xs={9}>
                    <Typography
                      className="info info-label"
                      sx={{
                        color: `${
                          setting?.general_text_color || "white"
                        } !important`,
                        fontFamily: branch.setting?.general_font,
                      }}
                    >
                      {t("index.moreInformation")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <ArrowForward className="info info-arrow" />
                  </Grid>
                </Grid>
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-around"
          spacing={22}
          sx={{ zIndex: checked ? -1 : "auto" }}
        >
          <Grid item>
            <Grid container direction="column" justifyContent="flex-start">
              <Grid item>
                <Grid container sx={{ marginLeft: "10px" }}>
                  <Grid item xs={12}>
                    <Typography
                      style={{ fontWeight: "bold" }}
                      className="branchName"
                      sx={{
                        color: branch?.setting?.general_text_color || "black",
                        fontFamily: branch.setting?.general_font,
                      }}
                    >
                      {branch?.name}
                    </Typography>
                  </Grid>
                  {branch?.subtitle && (
                    <Grid item xs={12}>
                      <Typography
                        className="branchSubtitle"
                        sx={{
                          fontWeight: "bold",
                          color: branch?.setting?.general_text_color || "black",
                          paddingTop: "0px !important",
                          fontSize: "14px !important",
                          fontFamily: branch.setting?.general_font,
                        }}
                      >
                        {branch?.subtitle}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {branch?.prefix === "T" && (
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Grid item>
                      <Chip
                        label="Delivey"
                        className="chip"
                        style={{ display: "none" }}
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        label="Take Away"
                        className="chip"
                        style={{ display: "none" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {branch?.prefix === "T" && (
            <Grid item>
              <Chip
                label="Menu diario"
                onDelete={() => console.log("hola")}
                deleteIcon={<ArrowDown style={{ color: "black" }} />}
                variant="outlined"
                style={{
                  border: "1px solid #E20613",
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          style={{
            width: "100%",
            zIndex: checked ? -1 : "auto",
            height: "70vh",
            overflowY: "auto",
          }}
        >
          {isLoading && (
            <Stack spacing={1}>
              <Grid container justifyContent="space-around" alignItems="center">
                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ fontSize: "1rem" }}
                        height={30}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ fontSize: "1rem" }}
                        height={30}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ fontSize: "1rem" }}
                        height={30}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ fontSize: "1rem" }}
                        height={30}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rectangular" height={60} />
                </Grid>
                <Grid item xs={11} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rounded" height={150} />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rectangular" height={60} />
                </Grid>
                <Grid item xs={11} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rounded" height={150} />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rectangular" height={60} />
                </Grid>
                <Grid item xs={11} sx={{ marginBottom: "10px" }}>
                  <Skeleton variant="rounded" height={150} />
                </Grid>
              </Grid>
            </Stack>
          )}
          {!isLoading && (
            <Categories
              search={search.toLowerCase()}
              currentLanguage={i18n.language}
            />
          )}
        </Grid>
        {branch?.prefix === "T" && (
          <OrderButton checked={checked} currentAmount={currentAmount} />
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnackbar && i18n.language !== branch.primary_language}
        key={"bottom center"}
      >
        <Alert
          onClose={() => dispatch(setShowSnackbar())}
          severity="info"
          variant="filled"
          sx={{
            width: "100%",
            fontFamily: setting?.general_font,
            background:
              setting?.background_color_main_button ||
              "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
            color: setting?.text_color_main_button || "white",
          }}
        >
          {t("index.snackbar")}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Index;
