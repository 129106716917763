import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from '@mui/material';

const DeleteDialog = ({open, setOpen, deleteOrder, setIdToDelete}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>¿Estás seguro de eliminar la orden?</DialogTitle>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancelar</Button>
        <Button onClick={deleteOrder}>Aceptar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog;
