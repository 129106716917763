import React from "react";
import vegan from "../../images/vegan.svg";
import { useTranslation } from "react-i18next";
import { Divider, Grid, ListItem, Typography } from "@mui/material";

const Diet = ({ setting, product, online, waiterAllergens }) => {
  const { t } = useTranslation();
  const customColor = {
    color: `${setting?.general_text_color || "black"} !important`,
  };
  const desktopStyle = {
    width: "50px !important",
    aspectRatio: "1 / 1",
    height: "50px",
  };
  const mobileStyle = { width: "5vw" };
  return (
    <React.Fragment>
      <ListItem>
        <Grid container direction="column" spacing={2}>
          <Grid
            item
            sx={{
              ...customColor,
              fontFamily: setting?.general_font,
              fontWeight: "bold",
            }}
          >
            {t("product.dietTypes")}
          </Grid>
          {product.allergens.map((a) => {
            const waiterAllergen = waiterAllergens?.find(
              (wa) => wa.allergen.id == a.id,
            );
            return (
              <Grid item key={a.id}>
                <Grid container spacing={2} alignItems="center">
                  { a.image && online && (
                  <Grid item>
                    <img
                      className="show-image"
                      src={a.image}
                      onError={(e) => {
                        if (e.target.src !== vegan) {
                          e.target.src = vegan;
                        }
                      }}
                      alt={a.name}
                      style={
                        window.screen.width >= 1344 ? desktopStyle : mobileStyle
                      }
                    />
                  </Grid>
                  )}
                  <Grid item>
                    <Typography
                      sx={{
                        ...customColor,
                        fontFamily: setting?.dishes_description_font,
                      }}
                    >
                      {a.name}{" "}
                      {waiterAllergen?.id &&
                        `/ ${waiterAllergen.allergen.name}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
};

export default Diet;
