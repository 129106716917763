import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { readLeftToRight } from "../../services/languages.js";

const Description = (props) => {
  const { product, branch, waiterDish } = props;
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const {
    branch: { setting },
  } = useSelector((state) => state.branch);
  const customColor = {
    color: `${setting?.general_text_color || "black"} !important`,
  };
  const hidePrice = product?.discount_value <= 0 || product?.price <= 0;
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: window.screen.width >= 1344 ? "590px" : "95vw",
          marginTop: product?.image ? "0px" : "20px",
        }}
        className="description-container"
      >
        <ListItem>
          <Grid
            container
            direction="column"
            sx={{ height: "auto", paddingTop: "20px" }}
            dir={readLeftToRight(language)}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  width: window.screen.width >= 1344 ? "590px" : "95vw",
                  ...customColor,
                  fontFamily: setting?.dishes_title_font,
                }}
              >
                {product.title} {waiterDish?.id && `/ ${waiterDish?.title}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  ...customColor,
                  fontFamily: setting?.dishes_title_font,
                  paddingBottom: '10px',
                  display: hidePrice ? "none" : "block",
                }}
                >
                {branch?.currency?.symbol}
                {product.discount_percent > 0
                  ? product.discount_value
                  : product.price}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: product.description ? "block" : "none" }}
            >
              <Typography
                sx={{
                  ...customColor,
                  width: window.screen.width >= 1344 ? "590px" : "95vw",
                  fontFamily: setting?.dishes_description_font,
                }}
              >
                {product.description}{" "}
                {waiterDish?.id && `/ ${waiterDish.description}`}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      </Grid>
    </>
  );
};

export default Description;
