import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { Divider, Grid, ListItem } from "@mui/material";
import ProductPlaceholder from "../../../images/productPlaceholder.svg";
import { closeOrder } from "../../../reducers/info";
import Close from "../../../images/closeStore.svg";

const useStyles = makeStyles(() => ({
  restaurantLogo: {
    height: "50px",
    width: "50px",
    borderRadius: "36px",
    border: "5px solid white",
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1) !important",
  },
  backToStore: {
    fontSize: 8,
    marginTop: "30px",
  },
}));

const Header = ({ branch }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const splitPath = location.pathname.split("/");
  const TOKEN = 1;
  const TOKEN_VALUE = 2;
  const basePath = splitPath[TOKEN] + "/" + splitPath[TOKEN_VALUE];

  return (
    <Grid sx={{ marginBottom: "20px" }}>
      <ListItem>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>Pedido</Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item sx={{ marginTop: "50px", position: "absolute" }}>
                    <img
                      src={branch.image ? branch.image : ProductPlaceholder}
                      alt="tea"
                      className={classes.restaurantLogo}
                    />
                  </Grid>
                  <Grid item sx={{ marginLeft: "20vw", marginTop: "30px" }}>
                    {branch.name}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item align="right">
                <img
                  src={Close}
                  alt="close"
                  onClick={() => {
                    dispatch(closeOrder());
                    navigate(basePath);
                  }}
                />
              </Grid>
              <Grid item className={classes.backToStore}>
                Volver a la tienda
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Divider sx={{ width: "80vw", marginLeft: "20vw" }} />
    </Grid>
  );
};

export default Header;
