import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation, useNavigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import logo from "../../images/logo.svg";
import { Grid } from "@mui/material";
import { setLoading } from "../../reducers/loading";
import { setUser } from "../../reducers/user";
import { setSelectedDish } from "../../reducers/dishes.js";
import { fetchBranch, fetchProduct } from "../../services/useFetch";
import { auth } from "../../index";
import { anonymousSignIn } from "../../services/firebase";
import { setFirstOpen, setShowInvalidMessage } from "../../reducers/info";
import { validateObjectType } from "../../services/url";
import NotFound from "../../pages/NotFound";

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    background:
      "linear-gradient(90deg, #EE7B5A 0%, #E20613 50.67%, #CB171A 99.72%)",
    width: "100vw",
  },
  buttonLogo: {
    marginRight: "10px",
  },
  logo: {
    marginBottom: "25px",
  },
  login: {
    position: "absolute",
    textAlign: "center",
    margin: "auto",
    right: 0,
    left: 0,
    top: 0,
    marginTop: "15%",
  },
}));

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { id } = useParams();
  const { isLoading } = useSelector((state) => state.loading);
  const { user } = useSelector((state) => state.user);
  const { firstOpen, showInvalidMessage } = useSelector((state) => state.info);
  const { branch } = useSelector((state) => state.branch);
  const { selectedDish } = useSelector((state) => state.dishes);
  const location = useLocation();
  const { filters } = useSelector((state) => state.filter.filter);
  const branchInitValues = {
    name: "",
    subtitle: "",
    image: "",
    categories: [],
    orders: [],
    orderedOrders: [],
    prefix: "B",
    currentLanguage: i18n.language,
    currency: {},
  };

  useEffect(() => {
    if (!firstOpen) {
      if (branch?.id) {
        fetchBranch({
          branchInitValues: branch,
          urlName: branch.url_name,
          dispatch,
          filters,
          currentLanguage: i18n.language,
        });
      } else {
        fetchProduct({
          id,
          dispatch,
          navigate,
          setSelectedDish,
          currentLanguage: i18n.language,
          urlName: selectedDish.urlName,
        });
      }
    }
  }, [i18n.language]);

  useEffect(() => {
    if (user.uid === undefined) {
      anonymousSignIn(getAuth());
    }
    const objectType = validateObjectType(location);
    if (objectType.valid) {
      if (
        branch?.url_name !== objectType.urlName &&
        user.uid !== undefined &&
        objectType.urlObjectType === "menu"
      ) {
        fetchBranch({
          branchInitValues,
          urlName: objectType.urlName,
          dispatch,
          filters,
          navigate,
          currentLanguage: i18n.language,
        });
      } else if (
        selectedDish.branch_url_name !== objectType.urlName &&
        user.uid !== undefined &&
        objectType.urlObjectType === "product"
      ) {
        fetchProduct({
          id: objectType.dishId,
          dispatch,
          navigate,
          setSelectedDish,
          currentLanguage: i18n.language,
          urlName: objectType.urlName,
        });
      }
      dispatch(setFirstOpen(false));
    } else {
      dispatch(setShowInvalidMessage(true));
      dispatch(setLoading(false));
    }
    // const validTokenObject = validUrl(location.pathname);
    // // First we valid de url struct and the token value
    // if (validTokenObject.valid) {
    //   // Second step we login the user if there no one already logged
    //   const branchTokenValidation =
    //     (branch.token && validTokenObject.token !== branch.token) ||
    //     (branch.id === null && validTokenObject.prefix === "B");
    //   const dishTokenValidation =
    //     (selectedDish.token && validTokenObject.token !== selectedDish.token) ||
    //     (selectedDish.id === null && validTokenObject.prefix === "P");
    //   if (
    //     user.uid !== undefined &&
    //     (branchTokenValidation || refreshToken || dishTokenValidation)
    //   ) {
    //     dispatch(setRefreshToken(false));
    //     saveToLocalStorage(validTokenObject);
    //   } else {
    //     setValidToken(true);
    //     setCanChangeByLanguage(true);
    //   }
    // } else {
    //   setValidToken(false);
    //   setShowInvalidMessage(true);
    //   dispatch(setLoading(false));
    // }
  }, [user, location.pathname]);

  // si cambia el token
  //auth.onIdTokenChanged(firebaseUser => {
  //  const now = new Date().getTime()
  //  const expirationTime = firebaseUser?.stsTokenManager?.expirationTime;
  //  if(firebaseUser && now > expirationTime){
  //    firebaseUser.getIdToken(true)
  //      .then((accessToken) => {
  //        setValidToken(true);
  //        dispatch(setUser({ accessToken, uid: firebaseUser.uid}));
  //      })
  //      .catch((error) => {
  //      });
  //  }
  //})

  onAuthStateChanged(auth, (firebaseUser) => {
    if (user?.uid === undefined && firebaseUser?.uid !== undefined) {
      dispatch(
        setUser({
          accessToken: firebaseUser.accessToken,
          uid: firebaseUser.uid,
        })
      );
    }
  });

  //   if (firebaseUser && user?.accessToken !== firebaseUser?.accessToken) {
  //     const token = `Bearer ${firebaseUser.accessToken}`;
  //     const headers = {
  //       headers: {
  //         'Authorization': token
  //       }
  //     }
  //     // if(prefix === 'T') {
  //     //   const provider = { login_provider: 'Anonymous' }
  //     //   axios.post('login', provider, headers)
  //     //     .then(res => {
  //     //       if(res.data) {
  //     //         const userResponse = {
  //     //           ...res.data,
  //     //           accessToken: user.accessToken
  //     //         }
  //     //         // arrivesCartearUser({ user: userResponse, branchId, tableId, headers});
  //     //       }
  //     //     })
  //     //     .catch(error => console.log(error))
  //     // }
  //   }
  // })

  if (
    (user.uid === undefined && isLoading) ||
    (!branch.id && !selectedDish.id)
  ) {
    if(showInvalidMessage) {
      return <NotFound />
    } else {
      return (
        <Grid className={classes.root}>
          <Grid
            container
            direction="column"
            className={classes.login}
            alignItems="center"
          >
            <Grid item>
              <img src={logo} alt="logo" className={classes.logo} />
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }

  return <Outlet />;
};
export default Login;
