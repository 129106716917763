import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@mui/material";

const useStyles = makeStyles(() => ({
  popup: {
    marginTop: "-70px",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(128, 128, 128, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2000,
  },
  popupInner: {
    position: "relative",
    padding: "32px",
    width: "60%",
    maxWidth: "800px",
    backgroundColor: "white",
    boxShadow: "3px -4px 12px rgba(0, 0, 0, 0.2) !important",
    borderRadius: "15px 15px !important",
  },
  closeBtn: {
    textAlign: "center",
    padding: "5px",
    width: "25vw",
    height: "5vh",
    color: "white !important",
    background:
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important",
    borderRadius: "50px !important",
    textTransform: "capitalize !important",
    fontWeight: "bold !important",
    marginLeft: "10px",
    marginTop: "15px",
  },
  Title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "bold !important",
    borderBottom: " 1px solid lightgrey",
  },
  Text: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "15px",
    textAlign: "center",
    paddingTop: "10px",
  },
  closeBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "10px",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
  icon: {
    background:
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important",
    padding: "10px",
    borderRadius: "50px !important",
    width: "30px",
    height: "auto",
  },
  "@media (max-width: 915px) and (orientation:landscape)": {
    popup: {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "70vh",
      backgroundColor: "rgba(128, 128, 128, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 20,
    },
    popupInner: {
      height: "-50vh",
      position: "relative",
      padding: "18px",
      width: "80%",
      maxWidth: "800px",
      backgroundColor: "white",
      boxShadow: "3px -4px 12px rgba(0, 0, 0, 0.2) !important",
      borderRadius: "15px 15px !important",
    },
    closeBtn: {
      textAlign: "center",
      padding: "5px",
      width: "15vw",
      height: "5vh",
      color: "white !important",
      background:
        "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important",
      borderRadius: "50px !important",
      textTransform: "capitalize !important",
      fontWeight: "bold !important",
      marginLeft: "10px",
      marginTop: "15px",
    },
    Title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "bold !important",
      borderBottom: " 1px solid lightgrey",
    },
    Text: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "13px",
      textAlign: "center",
      paddingTop: "10px",
    },
    closeBtnContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "5px",
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px",
    },
    icon: {
      background:
        "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important",
      padding: "5px",
      borderRadius: "50px !important",
      width: "30px",
      height: "auto",
    },
  },
}));

function PopUp({ trigger, setTrigger }) {
  const classes = useStyles();
  const branch = useSelector((state) => state.branch.branch);
  const { selectedDish } = useSelector((state) => state.dishes);
  const setting = branch?.setting || selectedDish?.setting;
  const { t } = useTranslation();

  if (!trigger) {
    return null;
  }

  return (
    <div className={classes.popup}>
      <div className={classes.popupInner}>
        <Typography className={classes.Title} sx={{ fontFamily: setting?.general_font }}>{t("popUp.title")}</Typography>
        <Typography className={classes.Text} sx={{ fontFamily: setting?.general_font }}>{t("popUp.text")}</Typography>
        <Typography className={classes.Text} sx={{ fontFamily: setting?.general_font }}>{t("popUp.text2")}</Typography>
        <Typography className={classes.Text} sx={{ fontFamily: setting?.general_font }}>{t("popUp.text3")}</Typography>
        <div container className={classes.closeBtnContainer}>
          <Button
            className={classes.closeBtn} 
            sx={{ fontFamily: setting?.general_font }}
            onClick={() => setTrigger(false)}
          >                              
            {t("buttons.accept")}        
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PopUp;
