import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CircleFlag } from "react-circle-flags";
import axios from "axios";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { openOrder, setOpenSidebar } from "../../reducers/info";
import { ReactComponent as LanguagesIcon } from "../../images/languages.svg";
import InfoIcon from "@mui/icons-material/Info";
import "./sidebar/sidebar.css";
import {
  codeFlagsLanguage,
  readLeftToRight,
} from "../../services/languages.js";

const useStyles = makeStyles(() => ({
  cartear: {
    marginTop: 20,
    marginLeft: 10,
  },
  text: {
    fontWeight: "bold",
    color: "white",
  },
  paper: {
    width: 250,
    boxShadow: "5px 0px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "0px 40px 40px 0px",
    overflow: "hidden",
  },
}));

const Sidebar = ({ tableId }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const dispatch = useDispatch();
  const { openSidebar } = useSelector((state) => state.info);
  const { selectedDish } = useSelector((state) => state.dishes);
  const { branch } = useSelector((state) => state.branch);
  const setting = branch?.setting || selectedDish?.setting;
  const mode = window.localStorage.getItem("mode");
  const languages = codeFlagsLanguage(t);
  const countryCode = languages.find(
    (hash) => hash.code === i18n.language,
  ).flag;
  const getOrderedOrders = () => {
    axios
      .get(`tables/${tableId}`)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  window.onpopstate = () => {
    if (openSidebar) {
      window.history.back();
      dispatch(setOpenSidebar(false));
    }
  };

  return (
    <Grid key={"left"}>
      <SwipeableDrawer
        anchor={"left"}
        open={openSidebar}
        onClick={(event) => {
          if (event.target.className.includes("MuiBackdrop")) {
            window.history.back();
            dispatch(setOpenSidebar(false));
          }
        }}
        onOpen={() => {}}
        onClose={() => {}}
        PaperProps={{
          sx: {
            background: `${
              setting?.background_color_sidebar ||
              "linear-gradient(360deg, #E20613 -87.32%, #EE7B5A 80.13%"
            } !important`,
          },
          classes: {
            root: classes.paper,
          },
        }}
      >
        <Box>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            spacing={10}
          >
            <Grid item className={classes.cartear}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={6}
              >
                {(branch?.image || selectedDish.branch_image) && (
                  <Grid item xs={4}>
                    <img
                      src={branch.image || selectedDish.branch_image}
                      alt="Restaurant Logo"
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "20px",
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={8}>
                  <Typography
                    className="sidebarText"
                    sx={{
                      color: setting?.text_color_sidebar || "white",
                      fontWeight: "bold",
                      overflow: "hidden",
                      width: "150px",
                      marginLeft: "-20px",
                      fontFamily: setting?.general_font,
                    }}
                  >
                    {branch.name || selectedDish.branch_name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <List className="sidebarItem">
                <ListItem key={"Lenguajes"} disablePadding>
                  <ListItemButton component={Link} to="languages">
                    <ListItemIcon>
                      <CircleFlag countryCode={countryCode} height="30" />
                    </ListItemIcon>
                    <Typography
                      className="sidebarText"
                      sx={{
                        color: `${
                          setting?.text_color_sidebar || "white"
                        } !important`,
                        fontFamily: setting?.general_font,
                      }}
                      dir={readLeftToRight(language)}
                    >
                      {t("sidebar.languages.title")}
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem key={"MoreInformation"} disablePadding>
                  <ListItemButton component={Link} to="about">
                    <ListItemIcon>
                      <InfoIcon
                        sx={{ color: setting?.text_color_sidebar || "white" }}
                      />
                    </ListItemIcon>
                    <Typography
                      className="sidebarText"
                      sx={{
                        color: `${
                          setting?.text_color_sidebar || "white"
                        } !important`,
                        fontFamily: setting?.general_font,
                      }}
                      dir={readLeftToRight(language)}
                    >
                      {t("index.moreInformation")}
                    </Typography>
                  </ListItemButton>
                </ListItem>
                {mode === "T" && (
                  <ListItem key={"Pedidos"} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        dispatch(setOpenSidebar(false));
                        getOrderedOrders();
                        dispatch(openOrder({ type: "orderedOrders" }));
                      }}
                    >
                      <ListItemIcon></ListItemIcon>
                      <ListItemText primary={"Pedidos"} />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
    </Grid>
  );
};

export default Sidebar;
