import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  ListItem,
  TextField
} from '@mui/material';

const useStyles = makeStyles(() => ({
  textArea: {
    width: '90vw'
  },
}))

const Comments = (props) => {
  const classes = useStyles();
  const { order, text, setText } = props;

  return (
    <ListItem>
      <Grid container spacing={2}>
        <Grid item>
          ¿Quieres aclarar algo?
        </Grid>
        <Grid item>
          <TextField
            className={classes.textArea}
            rows={5}
            multiline
            variant="filled"
            placeholder='quieres aclarar algo'
            value={text}
            onChange={(e) => {
              setText({...order, comment: e.target.value})
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                borderRadius: '20px',
                color: '#C0C0C0',
                fontStyle: 'normal',
                fontFamily: 'Rubik, sans-serif',
              }
            }}
          />
        </Grid>
      </Grid>
    </ListItem>
  )
}

export default Comments;
