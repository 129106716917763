import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Alert,
  Slide,
  Snackbar,
} from '@mui/material';
import { closeInfo } from '../../reducers/info';

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const InfoSnackbar = ({type, message}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(closeInfo());
    }, 5000)
  }, [])

  return (
    <Snackbar
      TransitionComponent={TransitionDown}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open
      onClose={() => dispatch(closeInfo())}
      autoHideDuration={5000}
      sx={{ zIndex: 100000000 }}
    >
      <Alert elevation={6} variant="filled" severity={type} sx={{ textAlign: 'center', width: '100%' }} onClose={() => dispatch(closeInfo())}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default InfoSnackbar;
