export function readLeftToRight(language) {
  return language === "he" || language === "ar" ? "rtl" : "ltr";
}

export const codeFlagsLanguage = (t) => {
  return [ 
    { code: "es", flag: "es", language: t("sidebar.languages.es") },
    { code: "en", flag: "us", language: t("sidebar.languages.en") },
    { code: "pt", flag: "br", language: t("sidebar.languages.pt") },
    { code: "it", flag: "it", language: t("sidebar.languages.it") },
    { code: "zh", flag: "cn", language: t("sidebar.languages.zh") },
    { code: "ar", flag: "sa", language: t("sidebar.languages.ar") },
    { code: "fr", flag: "fr", language: t("sidebar.languages.fr") },
    { code: "he", flag: "il", language: t("sidebar.languages.he") },
    { code: "ru", flag: "ru", language: t("sidebar.languages.ru") },
    { code: "ja", flag: "jp", language: t("sidebar.languages.ja") },
    { code: "de", flag: "de", language: t("sidebar.languages.de") },
  ]
}

