import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Badge,
  Box,
  Grid,
} from '@mui/material';
import { openOrder } from '../../reducers/info';
import order from '../../images/order.svg';

const shapeStyles = { bgcolor: 'white', width: 40, height: 40 };
const shapeCircleStyles = { borderRadius: '50%' };
const circle = (
  <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles, textAlign: 'center' }}>
    <Grid sx={{ marginTop: '5px !important'}}>
      <img src={order} alt="order"/>
    </Grid>
  </Box>
);

const BadgeOrders = ({orders}) => {
  const dispatch = useDispatch();
  return (
    <Badge
      onClick={() => dispatch(openOrder())}
      overlap="circular"
      badgeContent={orders.length.toString()}
      sx={{
        "& .MuiBadge-badge": {
          color: 'white',
          background: 'linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)'
        }
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left"}}>
      {circle}
    </Badge>
  )
}

export default BadgeOrders;
