import { useDispatch } from 'react-redux';
import {
  Button,
  Grid,
  Paper,
} from '@mui/material';
import { openOrder } from '../../reducers/info';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => ({
  amount: {
    color: '#E20613',
    fontFamily: 'Rubik, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '14px',
    textAlign: 'center',
    marginRight: '-20vw',
    marginBottom: '3vh'
  },
  bottom: {
    position: 'fixed !important',
    bottom: '0px',
    width: '100vw',
    maxHeight: '5vh !important',
    marginTop: '-60px',
    boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.1) !important',
    borderRadius: '15px 15px 0px 0px !important',
    background: '#FAFBFD !important',
    padding: '15px 0px',
  },
  primaryButton: {
    padding: '5px 40px !important',
    color: 'white !important',
    background: 'linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important',
    borderRadius: '50px !important',
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
    marginRight: '15vw',
    marginBottom: '20px',
  },
}))

const OrderButton = ({ checked, currentAmount }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <Paper elevation={4} className={classes.bottom} sx={{ zIndex: checked ? -1 : 'auto'}}>
      <Grid container alignItems='center' justifyContent='center' onClick={() => dispatch(openOrder())}>
        <Grid item>
          <Button
            className={classes.primaryButton}
          >
            Pedido
          </Button>
        </Grid>
        <Grid item className={classes.amount}>
          $ {currentAmount.toFixed(2)}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default OrderButton;
