import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import NotFoundBackground from '../images/not_found.svg';
import NotFoundIcon from '../images/not_found_scan_icon.svg';
import { setLoading } from '../reducers/loading';

export default function  NotFound() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  dispatch(setLoading(false));
  return (
    <Grid container direction='column' alignItems='center' justifyContent='center' style={{ background: `url(${NotFoundBackground}`, width: 'auto', height: '105vh', padding: '10px'}} spacing={3}>
      <Grid item>
        <img src={NotFoundIcon} alt='scan' style={{ width: '30vw' }}/>
      </Grid>
      <Grid item>
        <Typography variant='h3'>
          {t('error.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='h5'>
          {t('error.subtitle')}
        </Typography>
      </Grid>
      <Grid item align='center'>
        <Typography variant='caption'>
          {t('error.caption')}
        </Typography>
      </Grid>
      <Grid item>
        <Button></Button>
      </Grid>
    </Grid>
  )
}
