import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProductPlaceholder from "../../images/productPlaceholder.svg";
import { setLoading } from "../../reducers/loading.js";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/viewer/dish.css";

const DishCard = ({ dish, currentDish, setSelectedDish }) => {
  const dispatch = useDispatch();
  const {
    branch: { setting },
  } = useSelector((state) => state.branch);
  return (
    <Grid
      item
      className="viewer-dish-margin"
      onClick={() => {
        if (currentDish?.id !== dish.id && currentDish?.id) {
          setSelectedDish(dish);
          dispatch(setLoading(true));
        }
      }}
      id={`${dish.id}_viewer`}
    >
      <Grid container direction="column" justifyContent="center">
        <Grid item xs={4}>
          <img
            src={dish.image ? dish.image : ProductPlaceholder}
            className="viewer-dish-image"
            onError={(e) => (e.target.src = ProductPlaceholder)}
          />
          <Typography
            variant="p"
            className="viewer-product-title"
            sx={{
              color: `${setting?.text_color_viewer || "black"} !important`,
              fontFamily: setting?.dishes_title_font,
            }}
          >
            {dish.title}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DishCard;
