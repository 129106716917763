import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ProductCard from "./ProductCard";
import { useTranslation } from "react-i18next";
import { readLeftToRight } from "../../../services/languages.js";
import { setLastDishScrollPosition } from "../../../reducers/info";
import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
// import '@sandstreamdev/react-swipeable-list/dist/styles.css';

const useStyles = makeStyles(() => ({
  categoryHeader: {
    position: "relative",
    width: "102vw !important",
    aspectRatio: "7 / 1",
    marginTop: "35px",
    backgroundSize: "cover !important",
    backgroundRepeat: "round !important",
  },
  description: {},
  title: {
    position: "relative",
    color: "white",
    marginLeft: "4% !important",
    fontWeight: "bold",
    fontSize: "18px",
    paddingTop: "-50",
  },
  subtitle: {
    color: "white",
    fontSize: "14px !importan",
    fontWeight: "bold",
    marginLeft: "4% !important",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  "@media (max-width: 915px) and (orientation:landscape)": {
    categoryHeader: {
      position: "relative",
      width: "100vw !important",
      height: "16vh",
      marginTop: "35px",
      backgroundSize: "cover !important",
      backgroundRepeat: "round !important",
    },
    title: {
      paddingTop: "0px",
      fontSize: "18px",
    },
    subtitle: {
      fontSize: "14px",
    },
  },
  "@media (max-width: 915px) and (orientation:portrait)": {
    categoryHeader: {
      position: "relative",
      width: "100% !important",
      aspectRatio: "7 / 1",
      marginTop: "35px",
      backgroundSize: "cover !important",
      backgroundRepeat: "round !important",
    },
    title: {
      paddingTop: "0px",
      fontSize: "18px",
    },
    subtitle: {
      fontSize: "14px",
    },
  },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait)":
    {
      categoryHeader: {
        position: "relative",
        width: "100vw !important",
        aspectRatio: "7 / 1",
        marginTop: "35px",
        backgroundSize: "cover !important",
        backgroundRepeat: "round !important",
      },
      description: {},
      title: {
        position: "relative",
        color: "white",
        marginLeft: "10% !important",
        fontWeight: "bold",
        fontSize: "22px",
        paddingTop: "-50",
      },
      subtitle: {
        color: "white",
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape)":
    {
      categoryHeader: {
        position: "relative",
        width: "100vw !important",
        aspectRatio: "7 / 1",
        marginTop: "35px",
        backgroundSize: "cover !important",
        backgroundRepeat: "round !important",
      },
      description: {},
      title: {
        position: "relative",
        color: "white",
        marginLeft: "10% !important",
        fontWeight: "bold",
        fontSize: "22px",
        paddingTop: "-50",
      },
      subtitle: {
        color: "white",
        fontSize: "20px",
        fontWeight: "bold",
      },
    },
  "@media (min-width: 1024px) and (orientation: landscape)": {
    categoryHeader: {
      aspectRatio: "15 / 1",
    },
    title: {
      position: "relative",
      color: "white",
      fontWeight: "bold",
      fontSize: "28px",
    },
    subtitle: {
      color: "white",
      fontSize: "26px",
      fontWeight: "bold",
      marginTop: "-250px",
    },
  },
}));

const ListProducts = ({ category }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loadingWaiterDish, setLoadingWaiterDish] = useState(false);
  const { i18n } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const branch = useSelector((state) => state.branch.branch);
  const [waiterDish, setWaiterDish] = useState({});
  const [triggerWaiterDish, setTriggerWaiterDish] = useState(false);

  useEffect(() => {
    if (!open && waiterDish?.id !== undefined) {
      window.history.back();
    }
  }, [open, waiterDish]);

  return category.dishes.map((dish) => (
    <SwipeableListItem
      swipeRight={{
        action: () => {},
      }}
      onSwipeProgress={(progress) => {
        if (progress >= 30 && !triggerWaiterDish) {
          setTriggerWaiterDish(true);
          setLoadingWaiterDish(true);
          if (branch.primary_language !== i18n.language) {
            setOpen(true);
          }
          window.history.pushState(null, null, window.location.href);
          const token = user.accessToken;
          const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            ApiKey: process.env.REACT_APP_BACKOFFICE_API_KEY,
          };
          fetch(
            `${process.env.REACT_APP_REQUEST_BASE_URL}/dishes/${dish.id}/waiter_dish`,
            {
              method: "get",
              headers: new Headers(headers),
            },
          )
            .then((res) => res.json())
            .then((dish) => setWaiterDish(dish))
            .catch((err) => console.log(err))
            .finally(() => {
              setLoadingWaiterDish(false);
              setTriggerWaiterDish(false);
            });
        }
      }}
      key={dish.id}
    >
      <Link
        to={`${location.pathname}/${dish.id}`}
        style={{ textDecoration: "none" }}
        onClick={(e) => {
          if (waiterDish.id !== undefined) {
            e.preventDefault();
            e.stopPropagation();
          }
          const dishContent = document.getElementById("dishContent");
          dispatch(setLastDishScrollPosition(dishContent.scrollTop));
        }}
      >
        <ProductCard
          product={dish}
          open={open}
          setOpen={setOpen}
          waiterDish={waiterDish}
          setWaiterDish={setWaiterDish}
          loadingWaiterDish={loadingWaiterDish}
        />
      </Link>
    </SwipeableListItem>
  ));
};

const CategoryCard = ({ category }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const language = i18n.language;
  const {
    branch: { setting },
  } = useSelector((state) => state.branch);
  const customColor = {
    color: `${setting?.text_color_categories || "white"} !important`,
  };
  return (
    <Grid
      key={`category_${category.id}`}
      container
      className={classes.categoryCard}
      style={{
        width: "100vw !important",
      }}
    >
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={classes.categoryHeader}
        style={{
          backgroundImage:
            window.screen.width >= 1344 ? null : `url(${category.image})`,
        }}
        dir={readLeftToRight(language)}
      >
        <Grid
          item
          xs={12}
          className={classes.title}
          sx={{ ...customColor, fontFamily: setting?.categories_font }}
        >
          {category.name}
        </Grid>
        {category?.description && (
          <Grid
            item
            xs={12}
            className={classes.subtitle}
            sx={{ ...customColor, fontFamily: setting?.categories_font }}
          >
            {category.description}
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Grid
          container
          direction={window.screen.width >= 1344 ? "row" : "column"}
          alignItems={
            window.screen.width >= 1344 ? "flex-start" : "space-around"
          }
          justifyContent={
            window.screen.width >= 1344 ? "flex-start" : "space-around"
          }
          sx={{ marginLeft: "-8px", width: '100vw' }}
          onContextMenu={(e) => e.preventDefault()}
        >
          {window.screen.width < 1344 && (
            <SwipeableList>
              <ListProducts category={category} />
            </SwipeableList>
          )}
          {window.screen.width >= 1344 && <ListProducts category={category} />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CategoryCard;
