import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  TextField
} from '@mui/material';
import Header from './Header';
import Orders from './Orders';
import Actions from './Actions';
import DeleteDialog from './DeleteDialog';
import { removeOrder } from '../../../reducers/branch';
import { openInfo } from '../../../reducers/info';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    marginTop: '1vh',
    fontFamily: 'Rubik, sans-serif !important'
  },
  header: {
    height: '30vh',
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(4px)',
    zIndex: 10,
  },
  body: {
    height: '70vh',
    background: 'white',
    marginTop: '-30px',
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
    zIndex: 10000,
    overflow: 'auto'
  },
  chipAccompaniment: {
    height: '25px !important',
    borderRadius: '50px !important',
    margin: '5px !important',
    fontSize: '10px !important',
    color: '#E20613 !important',
    background: 'white !important',
    boxShadow: '0px 4px 10px rgba(153, 153, 153, 0.15)',
    border: '1px solid transparent !important'
  },
  productTitle: {
    fontWeight: 'bold',
    color: '#292B2E'
  },
  finalPrice: {
    fontWeight: 'bold',
    color: '#666666'
  },
  originalPrice: {
    fontWeight: 'bold',
    color: '#C0C0C0',
    textDecorationLine: 'line-through'
  },
}))

const ListOrders = React.forwardRef((props, ref) => {
  const { branch } = props;
  const classes = useStyles();
const dispatch = useDispatch();
  const orderInfo = useSelector(state => state.info.order);
  const branchOrders = orderInfo.type === "orders" ? branch.orders : branch.orderedOrders;
  const [orders, setOrders] = useState(branchOrders || []);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState();

  const changeAmount = (order, quantity) => {
    const newOrders = [...orders]
    const orderIndex = newOrders.findIndex(o => o.id === order.id);
    const newOrder = {...order}
    newOrder.quantity += quantity
    newOrders[orderIndex] = newOrder
    if(newOrder.quantity >= 0) {
      setOrders([...newOrders]);
    }
  }

  const deleteOrder = () => {
    dispatch(removeOrder({ id: idToDelete }));
    setIdToDelete(null);
    dispatch(
      openInfo(
        {
          type: "success",
          message: "Orden borrada con éxito"
        }
      )
    );
  }

  useEffect(() => {
    if(orders) {
      const newAmount = orders.reduce((acc, o) => {
        const amount = o.discountPercent ? o.totalAmount * ((100 - o.discountPercent)/100) : o.totalAmount
        return acc + amount
      }, 0)
      setCurrentAmount(newAmount);
    }
  }, [orders])

  useEffect(() => {
    if(idToDelete) {
      setOpen(true)
    }
  }, [idToDelete])

  //const { orders } = useSelector(state => state.branch.branch);

  return (
    <Paper sx={{ height: '0vh' }} elevation={4} ref={ref}>
      <Grid container direction='column' className={classes.root}>
        <Grid item className={classes.header}/>
        <Grid item className={classes.body} sx={{ height: orderInfo.type === "orders" ? "70vh" : "72vh !important" }}>
          <List>
            <Header branch={branch}/>
            <Grid item sx={{ overflow: 'auto', height: '45vh' }}>
            <Orders classes={classes} orders={orders} changeAmount={changeAmount} setIdToDelete={setIdToDelete} orderType={orderInfo.type}/>
            </Grid>
          </List>
        </Grid>
        <Grid item>
          <Actions branch={branch} currentAmount={currentAmount.toFixed(2)} orderInfo={orderInfo}/>
        </Grid>
      </Grid>
      <DeleteDialog open={open} setOpen={setOpen} setIdToDelete={setIdToDelete} deleteOrder={deleteOrder}/>
    </Paper>
  )
})



export default ListOrders;
