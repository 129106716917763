import React from "react";
import { Grid } from "@mui/material";
import { TabContext } from "@mui/lab";
import TabHeader from "./TabHeader";
import TabBody from "./TabBody";

const MainBody = ({
  categories,
  setTab,
  tab,
  setSelectedDish,
  fullScreen,
  currentDish,
}) => {
  return (
    <Grid
      item
      sx={{
        width: "100vw",
        typography: "body1",
        height: "7vh !important",
        visibility: fullScreen ? "hidden" : "visible",
      }}
    >
      <TabContext value={tab}>
        <TabHeader categories={categories} setTab={setTab} tab={tab} />
        <TabBody
          categories={categories}
          currentDish={currentDish}
          setSelectedDish={setSelectedDish}
        />
      </TabContext>
    </Grid>
  );
};

export default MainBody;
