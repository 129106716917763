import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLongPress } from "@uidotdev/usehooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, Modal, Card, Chip, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { setDish } from "../../../reducers/dishes";
import starIcon from "../../../images/starIcon.svg";
import "react-lazy-load-image-component/src/effects/blur.css";
import { readLeftToRight } from "../../../services/languages.js";
import WaiterModal from "../../product/WaiterModal";
import { is3d, isSpin, isVideo } from "../../../services/url.js";

const useStyles = makeStyles(() => ({
  productTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    LineClamp: 2,
    WebkitBoxOrient: "vertical",
    fontWeight: "bold",
    fontSize: "1em",
    marginLeft: "20px",
    lineHeight: "1.2",
    paddingTop: "5px",
  },
  productAllergens: {
    marginTop: "5px",
  },
  productTitleDiscount: {
    lineHeight: "1.2",
    WebkitLineClamp: 2,
    LineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    fontWeight: "bold",
    fontSize: "1.em",
  },
  boldText: {
    fontWeight: "bold",
    marginLeft: "20px",
  },
  card: {
    height: "auto",
    marginTop: "20px",
    borderRadius: "18px !important",
    marginLeft: "14px",
  },
  cardDescription: {
    maxWidth: "98%",
    marginBottom: "1vh",
    textStyle: "none !important",
    WebkitLineClamp: 2,
    LineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  chip: {
    position: "relative",
    background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
    borderRadius: "60px !important",
    fontSize: "10px !important",
    height: "20px !important",
    marginRight: "5px",
    align: "bottom",
    marginTop: "auto",
  },
  chipContainer: {
    PaddingTop: "30px",
  },
  description: {
    fontSize: "0.7rem",
    color: "#666666",
    overflow: " hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 4,
    LineClamp: 4,
    lineHeight: "1.2",
    marginLeft: "20px",
  },
  discountChipContainer: {
    left: 0,
  },
  discountChip: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    paddingTop: "-20px",
    background: "linear-gradient(180deg, #F4B430 0%, #FFC800 100%)",
    borderRadius: "0px 50px 50px 0px !important",
    fontSize: "11px !important",
    height: "20px !important",
    paddingRight: "10px",
    justifyContent: "right",
    marginBottom: "5px",
    marginTop: "0px",
    marginLeft: "0px",
    fontWeight: "bold",
    width: "70px",
  },
  label: {
    padding: "3px",
  },
  priceWithDisctount: {
    fontWeight: "bold",
    color: "#C0C0C0",
    textDecorationLine: "line-through",
    PaddingTop: "-50px",
    fontSize: 15,
  },
  productImage: {
    height: "144px ",
    width: "144px",
    borderTopLeftRadius: "25px",
    borderBottomLeftRadius: "25px",
  },
  //mobile horizontal
  "@media (max-width: 768px) and (orientation:landscape)": {
    productTitle: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      LineClamp: 2,
      WebkitBoxOrient: "vertical",
      fontWeight: "bold",
      fontSize: "1.2em",
    },
    discountChip: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "flex",
      zIndex: "-1",
      paddingTop: "-20px",
      background: "linear-gradient(180deg, #F4B430 0%, #FFC800 100%)",
      borderRadius: "50px 0px 0px 50px !important",
      fontSize: "11px !important",
      height: "20px !important",
      paddingRight: "0px",
      justifyContent: "right",
      marginBottom: "5px",
      marginTop: "0px",
      fontWeight: "bold",
      fontSize: "1em",
      width: "70px",
    },
    chip: {
      position: "relative",
      background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
      borderRadius: "60px !important",
      fontSize: "15px !important",
      height: "20px !important",
      marginRight: "5px",
      align: "bottom",
      justifyContent: "center",
      marginTop: "auto",
    },
    chipContainer: {
      PaddingTop: "30px",
    },
    description: {
      fontSize: "1rem",
      color: "#666666",
      overflow: " hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 4,
      LineClamp: 4,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    label: {
      padding: "3px",
    },
    priceWithDisctount: {
      fontWeight: "bold",
      color: "#C0C0C0",
      textDecorationLine: "line-through",
      PaddingTop: "-50px",
    },
  },
  //larger mobiles
  "@media (min-width: 768px) and (max-width: 1000px) and (orientation: landscape)":
    {
      productTitle: {
        fontSize: "1.5em",
      },
      cardDescription: {
        paddingLeft: "10%",
      },
      chip: {
        position: "relative",
        background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
        borderRadius: "60px !important",
        fontSize: "18px !important",
        height: "25px !important",
        marginRight: "5px",
        align: "bottom",
        justifyContent: "center",
        marginTop: "auto",
      },
      chipContainer: {
        PaddingTop: "30px",
      },
      description: {
        fontSize: "1.3rem",
        color: "#666666",
        overflow: " hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 4,
        LineClamp: 4,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  //ipads larger
  "@media (min-width: 915px) and (max-width: 1366px) and (orientation: landscape)":
    {
      productTitle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        LineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontWeight: "bold",
        fontSize: "1.5em",
      },
      productTitleDiscount: {
        WebkitLineClamp: 2,
        LineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        fontWeight: "bold",
        fontSize: "1.em",
      },
      boldText: {
        fontWeight: "bold",
      },
      card: {
        height: "auto",
        width: "90vw !important",
        marginTop: "20px",
        padding: "10px 0px 10px 0px ",
        borderRadius: "18px !important",
        marginLeft: "8px",
        paddingRight: "25px",
      },
      cardDescription: {
        paddingLeft: "0%",
        marginBottom: "1vh",
        textStyle: "none !important",
        WebkitLineClamp: 2,
        LineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      chip: {
        position: "relative",
        background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
        borderRadius: "60px !important",
        fontSize: "18px !important",
        height: "25px !important",
        marginRight: "5px",
        align: "bottom",
        justifyContent: "center",
        marginTop: "auto",
      },
      chipContainer: {
        PaddingTop: "30px",
      },
      description: {
        fontSize: "1.3rem",
        color: "#666666",
        overflow: " hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 4,
        LineClamp: 4,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      discountChipContainer: {
        paddingLeft: "100px",
      },
      discountChip: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        zIndex: "-1",
        paddingTop: "-20px",
        background: "linear-gradient(180deg, #F4B430 0%, #FFC800 100%)",
        borderRadius: "50px 0px 0px 50px !important",
        height: "20px !important",
        paddingRight: "5px",
        justifyContent: "right",
        marginBottom: "5px",
        marginTop: "0px",
        fontWeight: "bold",
        fontSize: "1em",
        width: "70px",
      },
      label: {
        padding: "3px",
      },
      priceWithDisctount: {
        fontWeight: "bold",
        color: "#C0C0C0",
        textDecorationLine: "line-through",
        PaddingTop: "-50px",
      },
      productImage: {
        height: "200px ",
        maxWidth: "300px !important",
        width: "140%",
        borderTopRightRadius: "25px",
        borderBottomRightRadius: "25px",
        marginLeft: "-20px",
        marginTop: "-10px",
        marginBottom: "-45px",
      },
    },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait)":
    {
      productTitle: {
        fontSize: "1.2em",
      },
      description: {
        fontSize: "1rem",
      },
      discountChip: {
        height: "22px !important",
        paddingRight: "5px",
        justifyContent: "right",
        marginBottom: "5px",
        marginTop: "0px",
        fontWeight: "bold",
        fontSize: "1em",
        width: "70px",
      },
      card: {
        height: "auto",
        width: "90vw !important",
        marginTop: "20px",
        padding: "10px 0px 10px 16px ",
        borderRadius: "18px !important",
        marginLeft: "0.5%",
        paddingRight: "3%",
      },
      chip: {
        position: "relative",
        background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
        borderRadius: "60px !important",
        fontSize: "18px !important",
        height: "25px !important",
        marginRight: "5px",
        align: "bottom",
        justifyContent: "center",
        marginTop: "auto",
      },
      chipContainer: {
        PaddingTop: "30px",
      },
    },
  "@media (min-width: 1024px) and (orientation: portrait)": {
    productTitle: {
      fontSize: "1.5em",
    },
    card: {
      height: "auto",
      width: "90vw !important",
      marginTop: "20px",
      padding: "10px 0px 10px 16px ",
      borderRadius: "18px !important",
      marginLeft: "18px",
      paddingRight: "25px",
    },
    description: {
      fontSize: "1.3rem",
      color: "#666666",
      overflow: " hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 4,
      LineClamp: 4,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    cardDescription: {
      paddingLeft: "5%",
      marginBottom: "1vh",
      textStyle: "none !important",
      WebkitLineClamp: 2,
      LineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    chip: {
      position: "relative",
      background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
      borderRadius: "60px !important",
      fontSize: "18px !important",
      height: "25px !important",
      marginRight: "5px",
      align: "bottom",
      justifyContent: "center",
      marginTop: "auto",
    },
    chipContainer: {
      PaddingTop: "30px",
    },
  },
  "@media (min-width: 1366px) and (orientation: landscape)": {
    productTitle: {
      fontSize: "2em",
    },
    description: {
      fontSize: "1.8rem",
      color: "#666666",
      overflow: " hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      LineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    cardDescription: {
      paddingLeft: "-25%",
      marginBottom: "1vh",
      textStyle: "none !important",
      WebkitLineClamp: 2,
      LineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    chip: {
      position: "relative",
      background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
      borderRadius: "60px !important",
      fontSize: "25px !important",
      height: "25px !important",
      marginRight: "5px",
      align: "bottom",
      justifyContent: "center",
      marginTop: "auto",
    },
    chipContainer: {
      PaddingTop: "30px",
    },
    productImage: {
      height: "190px ",
      maxWidth: "300px !important",
      width: "140%",
      borderTopRightRadius: "25px",
      borderBottomRightRadius: "25px",
      marginLeft: "0px",
      marginBottom: "-45px",
    },
    card: {
      height: "200px",
      width: "20vw !important",
      marginTop: "20px",
      padding: "10px 0px 10px 0px ",
      borderRadius: "18px !important",
      marginLeft: "110px",
    },
    discountChipContainer: {
      paddingRight: "-350px",
    },
    discountChip: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "flex",
      zIndex: "-1",
      paddingTop: "-20px",
      background: "linear-gradient(180deg, #F4B430 0%, #FFC800 100%)",
      borderRadius: "50px 0px 0px 50px !important",
      height: "20px !important",
      paddingRight: "25px",
      justifyContent: "right",
      marginBottom: "5px",
      marginTop: "0px",
      fontWeight: "bold",
      fontSize: "1.2em !important",
      width: "90px",
    },
  },
}));

const ProductCard = ({
  product,
  open,
  setOpen,
  waiterDish,
  setWaiterDish,
  loadingWaiterDish,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const is_3d = is3d(product.url_3d_web);
  const is_360 = isSpin(product.url_3d_web);
  const is_video = isVideo(product.url_3d_web);
  const branch = useSelector((state) => state.branch.branch);
  const { setting, currency } = branch;
  const productHasExtras =
    product.toppings.length > 0 ||
    product.accompaniments.length > 0 ||
    product.variants.length > 0;
  const customColor = {
    color: `${setting?.general_text_color || "black"} !important`,
  };

  const chipColor = {
    color: `${setting?.text_color_main_button || "white"} !important`,
    background: `${
      setting?.background_color_main_button ||
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)"
    } !important`,
    fontFamily: setting?.general_font,
  };

  const discountChipColor = {
    color: `${setting?.text_color_main_button || "white"} !important`,
    background: `${
      setting?.background_color_main_button ||
      "linear-gradient(180deg, #F4B430 0%, #FFC800 100%)"
    } !important`,
    fontFamily: setting?.general_font,
  };

  window.onpopstate = () => {
    if (open) {
      setOpen(false);
    }
  };

  return (
    <Card
      id={product.id}
      key={`product_${product.id}`}
      sx={{
        backgroundColor: setting?.dish_color || "white",
        display: setting?.hide_dish_image ? "block" : "-webkit-box !important",
        width: "100vw",
        borderRadius: "20px",
      }}
      className={classes.card}
    >
      {open &&
        i18n.language !== branch.primary_language &&
        waiterDish?.id == product?.id && (
          <WaiterModal
            loadingWaiterDish={loadingWaiterDish}
            open={open}
            setOpen={setOpen}
            dish={waiterDish}
            setting={setting}
            setWaiterDish={setWaiterDish}
          />
        )}
      <Grid container alignItems="center" justifyContent="center">
        <Grid
          item
          xs={
            setting ? (setting.hide_dish_image || !product.image ? 12 : 7) : 7
          }
          sx={{
            borderRadius: "30px",
            width: "100%",
          }}
        >
          <Grid
            container
            className={classes.cardDescription}
            justifyContent="flex-start"
            sx={{ width: "100%" }}
          >
            {product.discount_percent > 0 && (
              <Grid
                item
                xs={12}
                align="left"
                className={classes.discountChipContainer}
              >
                <Chip
                  className={classes.discountChip}
                  label={`-${product.discount_percent}%`}
                  sx={discountChipColor}
                />
              </Grid>
            )}
            <Grid
              item
              className={classes.productTitleDiscount}
              dir={readLeftToRight(language)}
              xs={12}
            >
              <Grid container alignItems="center" spacing={0.5}>
                <Grid item>
                  <Typography
                    variant="p"
                    className={classes.productTitle}
                    sx={{
                      ...customColor,
                      fontFamily: setting?.dishes_title_font,
                    }}
                  >
                    {product.title}
                    {product?.allergens?.length > 0 &&
                      product?.allergens
                        ?.filter((a) => a.image)
                        ?.map((a) => (
                          <img
                            src={a.image}
                            style={{
                              width:
                                window.screen.width >= 1344 ? "1vw" : "3.5vw",
                              marginLeft: "2px",
                            }}
                            key={a.id}
                            alt={product.title}
                          />
                        ))}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              max-width="-webkit-fill-available"
              dir={readLeftToRight(language)}
            >
              <Typography
                variant="p"
                className={classes.description}
                sx={{
                  ...customColor,
                  fontFamily: setting?.dishes_description_font,
                }}
              >
                {product.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                style={{
                  display:
                    product.price <= 0 || product.discount_value <= 0
                      ? "none"
                      : "flex",
                }}
              >
                {product.discount_percent > 0 && (
                  <Grid item>
                    <Typography
                      variant="p"
                      className={classes.boldText}
                      sx={{
                        ...customColor,
                        fontFamily: setting?.dishes_description_font,
                      }}
                    >
                      {currency.symbol} {product.discount_value}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  item
                  dir={language === "he" || language === "ar" ? "rtl" : "ltr"}
                >
                  <Typography
                    variant="p"
                    className={
                      product.discount_percent > 0
                        ? classes.priceWithDisctount
                        : classes.boldText
                    }
                    sx={{
                      ...customColor,
                      fontFamily: setting?.dishes_description_font,
                    }}
                  >
                    {currency.symbol} {product.price}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ marginLeft: "20px" }}>
              <Grid container alignItems="center" justifyContent="space-around">
                <Grid item>
                  {product.is_recommended && (
                    <img
                      key={`startIcon${product.id}`}
                      src={starIcon}
                      style={{
                        width: window.screen.width >= 1344 ? "25px" : "20px",
                        aspectRatio: "1/1",
                        marginTop: "6px",
                      }}
                    />
                  )}
                </Grid>
                <Grid item>
                  {productHasExtras && (
                    <Chip
                      className={classes.chip}
                      label={t("category.product.extras")}
                      sx={chipColor}
                    />
                  )}
                </Grid>
                <Grid item align="left">
                  {is_3d && (
                    <Chip
                      className={classes.chip}
                      label={t("category.product.dish3d")}
                      sx={{ ...chipColor }}
                    />
                  )}
                </Grid>
                <Grid item align="left">
                  {is_360 && (
                    <Chip
                      className={classes.chip}
                      label={t("category.product.dish360")}
                      sx={{ ...chipColor }}
                    />
                  )}
                </Grid>
                <Grid item align="left">
                  {is_video && (
                    <Chip
                      className={classes.chip}
                      label={t("category.product.dishVideo")}
                      sx={{ ...chipColor }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {(setting ? !setting.hide_dish_image && product.image : true) && (
          <Grid
            item
            xs={5}
            sx={{
              aspectRatio: "1 / 1",
              backgroundImage: `url(${product.image})`,
              backgroundSize: "cover",
              backgroundRepeat: "round",
              borderRadius: "18px",
            }}
          />
        )}
      </Grid>
    </Card>
  );
};

export default ProductCard;
