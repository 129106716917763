import { createSlice } from "@reduxjs/toolkit";

export const persistedReducer = createSlice({
  name: "persisted",
  initialState: {
    currentLanguage: "es",
  },
  reducers: {
    setCurrentLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
  },
});

export const { setCurrentLanguage } = persistedReducer.actions;

export default persistedReducer.reducer;
