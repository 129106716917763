const ITTranslation = {
  it: {
    translation: {
      popUp: {
        title: "Aiuta a usare la Realtà Aumentata. ",
        text: "1. Punta la fotocamera verso un tavolo o una superficie luminosa.",
        text2:
          "2. Muovi lentamente il cellulare in cerchio finché il disegno della mano non scompare.",
        text3:
          "3. Usa un dito per muovere il piatto e due per ruotarlo. Se avvicini la fotocamera alla parabola, la vedrai più in dettaglio!",
      },
      buttons: {
        accept: "Accettare",
      },
      sidebar: {
        languages: {
          title: "Le lingue",
          es: "Spagnolo",
          en: "inglese",
          pt: "portoghese",
          it: "Italiano",
          zh: "Cineseo",
          ar: "Araboي",
          fr: "Francese",
          he: "Ebraico",
          ru: "Russo",
          ja: "Giapponese",
          de: "Tedesco",
        },
      },
      index: {
        allCategories: "Vedi tutto",
        searchPlaceholder: "Cosa vuoi ordinare?",
        filter: "Filtro",
        viewMenu3D: "VEDI MENU 3D",
        moreInformation: "Ulteriori informazioni",
        snackbar:
          "Scorri un dito da sinistra a destra su un piatto per tradurlo nella lingua del cameriere.",
      },
      category: {
        product: {
          dish3d: "Piatto 3D",
          outstanding: "Eccezionale",
          extras: "Extras",
          dish360: "Piatto 360",
          dishVideo: "Video",
        },
      },
      product: {
        see360: "VEDERE IN 360",
        see3D: "GUARDA IN 3D",
        seeVideo: "VEDI VIDEO",
        ar: "REALTÀ AUMENTATA (AR)",
        dietTypes: "Tipi dietetici - Specifiche",
        waiterButton: "TRADUCI PIATTO PER CAMERIERE",
      },
      product3D: {
        viewAR: "VEDERE IN REALTÀ AUMENTATA (AR)",
        view3D: "GUARDA IN 3D",
      },
      filter: {
        title: "Filtro",
        dietTypes: "Tipi dietetici - Specifiche",
        dishes3D: "Piatti 3D / 360 / Video",
        clear: "Cancellare",
        apply: "Applicare",
        featuredDishes: "Prodotti in evidenza",
        withDiscount: "Con sconto",
      },
      error: {
        title: "Oh!",
        subtitle: "Qualcosa è andato storto",
        caption:
          "Assicurati che il QR o il link appartenga a una struttura associata a Cartear o riprova.",
      },
      about: {
        daysAndSchedules: "Giorni e Orari",
        location: "Posizione",
        coverCharge: "Coperto",
        socialNetworks: "Reti Sociali",
      },
      days: {
        0: "Lunedì",
        1: "Martedì",
        2: "Mercoledì",
        3: "Giovedì",
        4: "Venerdì",
        5: "Sabato",
        6: "Domenica",
      },
    },
  },
};

export default ITTranslation;
