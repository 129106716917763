import { createSlice } from "@reduxjs/toolkit";

export const filterReducer = createSlice({
  name: "filter",
  initialState: {
    filter: {
      filters: null,
    },
  },
  reducers: {
    setFilters: (state, action) => {
      state.filter.filters = action.payload;
    },
  },
});

export const { setFilters } = filterReducer.actions;

export default filterReducer.reducer;
