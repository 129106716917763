import { createSlice } from '@reduxjs/toolkit';
export const dishesReducer = createSlice({
  name: 'dishes',
  initialState: {
    dishes: [],
    selectedDish: {
      id: null
    }
  },
  reducers: {
    addDish: (state, action) => {
      const index = state.dishes.findIndex(d => d.id === action.payload.id);
      if(index !== -1) {
        state.dishes[index] = action.payload
      } else {
        state.dishes = [...state.dishes, action.payload];
      }
    },
    setDishes: (state, action) => {
      state.dishes = action.payload;
    },
    setSelectedDish: (state, action) => {
      state.selectedDish = action.payload;
    },
    setDish: (state, action) => {
      const index = state.dishes.findIndex(d => d.id === action.payload.id);
      const newDishes = [...state.dishes]
      newDishes[index] = {...newDishes[index], loaded: true }
      state.dishes = newDishes;
    }
  }
})

export const {
  addDish, setSelectedDish, setDish, setDishes,
} = dishesReducer.actions;

export default dishesReducer.reducer;
