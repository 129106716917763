import React, { forwardRef } from "react";
import { TabPanel } from "@mui/lab";
import DishesCard from "./DishesCard";
import "../../styles/viewer/dish.css";

const TabBody = forwardRef((props, ref) => {
  const { categories, currentDish, setSelectedDish } = props;
  const scrollStyles = {
    maxHeight: "300px", // Set a maximum height for the container
    overflowY: "scroll", // Enable vertical scrolling
    scrollbarWidth: "none", // Hide the scrollbar for Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hide the scrollbar for Chrome, Safari, and Opera
    },
  };

  if (!categories) {
    return <></>;
  }

  return (
    categories &&
    categories.map((category, index) => (
      <TabPanel
        value={String(index)}
        key={`tabpanel ${category.id}`}
        index={String(index)}
        ref={ref}
        className="viewer-panel-root"
        style={scrollStyles}
        id={`tab_panel_categories_viewer_${index}`}
      >
        <DishesCard
          currentDish={currentDish}
          dishes={category.dishes}
          setSelectedDish={setSelectedDish}
        />
      </TabPanel>
    ))
  );
});

export default TabBody;
