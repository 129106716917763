const ARTranslation = {
  ar: {
    translation: {
      popUp: {
        title: "تساعد على استخدام الواقع المعزز",
        text: "1. وجّه الكاميرا إلى طاولة أو سطح خفيف. ",
        text2: "2. حرك هاتفك الخلوي ببطء في دوائر حتى يختفي رسم اليد.",
        text3:
          "3. استخدم إصبعًا واحدًا لتحريك الطبق وإصبعين لتدويره. إذا قرّبت الكاميرا من الطبق ، فسترى ذلك بمزيد من التفصيل!",
      },
      buttons: {
        accept: "يقبل",
      },
      sidebar: {
        languages: {
          title: "اللغات",
          es: "الأسبانية",
          en: "إنجليزي",
          pt: "البرتغالية",
          it: "إيطالي",
          zh: "صينى",
          ar: "عربي",
          fr: "فرنسي",
          he: "اللغة العبرية",
          ru: "الروسية",
          ja: "اليابانية",
          de: "ألمانية",
        },
      },
      index: {
        allCategories: " أاظهار الكل",
        searchPlaceholder: " ماذا تريد أن تطلب؟",
        filter: " منقي",
        viewMenu3D: "انظر قائمة 3D",
        moreInformation: "مزيد من المعلومات",
        snackbar:
          "انزلق بإصبعك من اليسار إلى اليمين على طبق لترجمته إلى لغة النادل.",
      },
      category: {
        product: {
          dish3d: "لوحة ثلاثية الأبعاد",
          outstanding: " أمتياز",
          extras: "إضافات",
          dish360: "طبق 360",
          dishVideo: "فيديو",
        },
      },
      product: {
        see360: "شاهد بزاوية 360",
        see3D: "انظر في 3D",
        seeVideo: "مشاهدة الفيديو",
        ar: "الواقع المعزز (AR)",
        dietTypes: "أنواع الحمية - المواصفات",
        waiterButton: "ترجمة الطبق للنادل",
      },
      product3D: {
        viewAR: "انظر في الواقع المعزز (AR)",
        view3D: "انظر في 3D",
      },
      filter: {
        title: " منقي",
        dietTypes: "أنواع الحمية - المواصفات",
        dishes3D: "أطباق 3D / 360 / فيديو",
        clear: "محو",
        apply: "تطبيق",
        featuredDishes: "المنتجات المميزة",
        withDiscount: "مع خصم",
      },
      error: {
        title: "!أوه",
        subtitle: "أهناك خطأ ما",
        caption:
          ".تأكد من أن QR أو الرابط ينتمي إلى مؤسسة مرتبطة بـ Cartear أو حاول مرة أخرى",
      },
      warning: {
        title: " منقي",
        text: "أنواع النظام الغذائي",
        button: "أطباق ثلاثية الأبعاد",
      },
      about: {
        daysAndSchedules: "أيام وأوقات",
        location: "موقع",
        coverCharge: "رسوم التغطية",
        socialNetworks: "شبكات اجتماعية",
      },
      days: {
        0: "الاثنين",
        1: "الثلاثاء",
        2: "الأربعاء",
        3: "الخميس",
        4: "الجمعة",
        5: "السبت",
        6: "الأحد",
      },
    },
  },
};

export default ARTranslation;
