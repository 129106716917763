import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { JavascriptViewer } from "@3dweb/360javascriptviewer";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BackgroundProductViewer from "../images/background_product_viewer.png";
import MainBody from "../components/product3d/MainBody.js";
import { setLoading } from "../reducers/loading";
import { setSelectedDish } from "../reducers/dishes.js";
import Header from "../components/product3d/Header";
import Viewer from "../components/product3d/Viewer";
import { readLeftToRight } from "../services/languages.js";
import { current } from "@reduxjs/toolkit";

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    backgroundRepeat: "round",
    overflow: "hidden",
  },
  holder: {
    margin: "auto",
    height: "300px",
    width: "300px",
  },
  image360: {
    margin: "auto",
    height: "300px",
    width: "300px",
    marginTop: "150px",
  },
  dish: {
    marginTop: "20vh",
    paddingLeft: "20px",
    position: "absolute",
  },
  text: {
    paddingTop: "20px",
    fontSize: "1em",
    fontWeight: "bold",
    paddingRight: "20px",
  },
  actions: {
    paddingLeft: "-40px",
    width: "100%",
    position: "absolute",
    bottom: "21.5%",
  },
  price: {
    paddingTop: "5px",
    fontSize: "0.8em",
    fontWeight: "bold",
    paddingRight: "20px",
  },
  description: {
    paddingTop: "5px",
    fontSize: "0.8em",
    color: "#666666",
    fontWeight: "bold",
    paddingRight: "20px",
  },
  gridDishPlaceholder: {
    marginLeft: "0vw",
    marginTop: "-5vh",
  },
  dishPlaceholder: {
    position: "relative",
    marginTop: "-100px",
    width: "80vw",
    zIndex: 1,
  },
  switchButton: {
    "&:focus": {
      borderColor: "#EE7B5A",
    },
    fontWeight: "bold",
    fontSize: "10px",
    width: "65vw",
    padding: "10px !important",
    borderRadius: "60px",
    borderColor: "#EE7B5A",
    left: "18%",
    marginBottom: "20vh",
    zIndex: 0,
  },
  propmtImg: {
    animation: "circle 4s linear infinite",
  },
  "@media (max-width: 915px) and (orientation:landscape)": {
    switchButton: {
      "&:focus": {
        borderColor: "#EE7B5A",
      },
      fontWeight: "bold",
      fontSize: "10px",
      width: "35vw",
      padding: "10px !important",
      borderRadius: "60px",
      borderColor: "#EE7B5A",
      top: "74%",
      left: "35%",
      right: 0,
      marginBottom: "30vh",
      zIndex: 10,
      align: "bottom",
    },
    actions: {
      paddingLeft: "130px",
      width: "40%",
      position: "absolute",
      bottom: "27%",
    },
    text: {
      fontSize: "1em",
      fontWeight: "bold",
    },
    dish: {
      marginLeft: "68%",
      width: "32vw",
      top: -20,
    },
    title: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      LineClamp: 2,
      WebkitBoxOrient: "vertical",
      fontWeight: "bold",
      whiteSpace: "break-spaces !important",
      height: "30%",
    },
    description: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      LineClamp: 2,
      WebkitBoxOrient: "vertical",
      fontWeight: "bold",
      whiteSpace: "break-spaces !important",
      height: "30%",
    },
  },
  "@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait)":
    {
      text: {
        paddingTop: "0px",
        fontSize: "2em",
        fontWeight: "bold",
      },
      price: {
        paddingTop: "5px",
        fontSize: "1em",
        fontWeight: "bold",
      },
      description: {
        paddingTop: "5px",
        fontSize: "1em",
        color: "#666666",
        fontWeight: "bold",
      },
    },
  "@media (min-width: 915px) and (max-width: 1366px) and (orientation: landscape)":
    {
      text: {
        paddingTop: "0px",
        fontSize: "2em",
        fontWeight: "bold",
      },
      price: {
        paddingTop: "5px",
        fontSize: "1em",
        fontWeight: "bold",
      },
      description: {
        paddingTop: "5px",
        fontSize: "1em",
        color: "#666666",
        fontWeight: "bold",
      },
    },
}));

const Product3d = (props) => {
  const { id } = useParams();
  const { orders, categories } = useSelector((state) => state.branch.branch);
  const { selectedDish } = useSelector((state) => state.dishes);
  const branch = useSelector((state) => state.branch.branch);
  const dishes = useSelector((state) => state.dishes.dishes);
  const { isLoading } = useSelector((state) => state.loading);
  const [categories3d, setCategories3d] = useState(categories || []);
  const [tab, setTab] = useState("0");
  const [show, setShow] = useState(true);
  const [currentBranchDish, setCurrentBranchDish] = useState({});
  const { i18n } = useTranslation();
  const [fullScreen, setFullScreen] = useState(false);
  const [totalFrames, setTotalFrames] = useState(0);
  const classes = useStyles();
  const setting = branch?.setting || selectedDish?.setting;
  const dispatch = useDispatch();
  const currency = branch?.currency || selectedDish?.currency;
  const language = i18n.language;
  const customColor = {
    color: `${setting?.text_color_viewer || "black"} !important`,
  };
  let dish = selectedDish?.id ? selectedDish : currentBranchDish;

  const chipColor = {
    color: `${setting?.text_color_main_button || "white"} !important`,
    background: `${
      setting?.background_color_main_button ||
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)"
    } !important`,
  };

  useEffect(() => {
    if (branch.prefix === "B") {
      let currentTab = 0;
      let dish;
      let currentCategory = {};
      if (categories) {
        const newCategories = categories.filter((category) =>
          category.dishes.some((dish) => dish.url_3d_web),
        );
        newCategories.forEach((category, index) =>
          category.dishes.forEach((product) => {
            if (product.id.toString() === id) {
              dish = product;
              currentCategory = category;
              currentTab = index;
              return false;
            }
          }),
        );
        if (currentCategory) {
          setTab(String(currentTab));
          if (dish) {
            setCurrentBranchDish(dish);
          } else {
            //navigate('not_found')
          }
        }
        setCategories3d(newCategories);
        dispatch(setLoading(false));
      }
    }
  }, [branch]);

  useEffect(() => {
    if (branch) {
      const dish = dishes.find((d) => d.id === Number(id));
      setCurrentBranchDish(dish);
    }
  }, [branch]);

  const isFromBranch = branch.id !== null;

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      sx={{
        overflow: "hidden !important",
        background: `url(${
          setting?.background_3d_image || BackgroundProductViewer
        })`,
      }}
    >
      {branch.prefix === "B" && (
        <Grid item sx={{ marginBottom: "20px" }}>
          <Header orders={orders} fullScreen={fullScreen} setting={setting} />
        </Grid>
      )}
      <Grid item sx={{ marginTop: "6vh !important" }}>
        {isFromBranch && (
          <MainBody
            categories={categories3d}
            setTab={setTab}
            tab={tab}
            currentDish={selectedDish?.id ? selectedDish : currentBranchDish}
            setSelectedDish={setCurrentBranchDish}
            fullScreen={fullScreen}
          />
        )}
        <Grid
          item
          sx={{ display: fullScreen ? "none" : "flex" }}
          dir={readLeftToRight(language)}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            className={classes.dish}
          >
            <Grid container className={classes.text} spacing={1}>
              <Grid
                item
                sx={{
                  zIndex: 10,
                  ...customColor,
                  fontFamily: setting?.dishes_title_font,
                }}
              >
                {dish?.title}
              </Grid>
              <Grid item>
                {dish?.allergens?.length > 0 &&
                  dish?.allergens
                    ?.filter((a) => a.image)
                    ?.map((a) => (
                      <img
                        src={a.image}
                        style={{ width: "3.5vw" }}
                        key={a.id}
                        alt={dish.title}
                      />
                    ))}
              </Grid>
            </Grid>
            <Grid
              item
              className={classes.description}
              sx={{
                zIndex: 10,
                ...customColor,
                fontFamily: setting?.dishes_description_font,
              }}
            >
              {dish?.description}
            </Grid>
            {dish && (
              <Grid
                item
                className={classes.price}
                sx={{
                  zIndex: 10,
                  ...customColor,
                  fontFamily: setting?.dishes_description_font,
                }}
              >
                {dish?.discount_value
                  ? `${currency.symbol} ${dish?.discount_value}`
                  : `${currency.symbol} ${dish?.price}`}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Viewer
            categories3d={categories3d}
            dish={selectedDish?.id ? selectedDish : currentBranchDish}
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            setSelectedDish={setCurrentBranchDish}
            setTab={setTab}
            setShow={setShow}
            show={show}
            tab={tab}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Product3d;
