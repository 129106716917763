import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import PopUp from "../../components/utils/PopUp";
import trash from "../../images/trash.svg";
import viewProduct3d from "../../images/viewProduct3d.svg";
import { ReactComponent as NormalScreenPicture } from "../../images/normalScreen.svg";
import { ReactComponent as FullScreenPicture } from "../../images/fullScreen.svg";
import zoomAbled from "../../images/lupa.png";
import zoomDisabled from "../../images/lupaGrey.png";
import { makeStyles } from "@mui/styles";
import { addOrder } from "../../reducers/branch";
import { openInfo } from "../../reducers/info";
import { isAndroid } from "react-device-detect";
import InfoButton from "./InfoButton";

const useStyles = makeStyles(() => ({
  primaryButton: {
    textAlign: "center",
    padding: "5px",
    width: "4vw",
    height: "5vh",
    color: "white !important",
    background:
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important",
    borderRadius: "50px !important",
    textTransform: "capitalize !important",
    fontWeight: "bold !important",
    marginLeft: "10px",
    marginTop: "-15px",
  },
  infoButton: {
    position: "fixed",
    bottom: "12%",
    left: "8%",
    zIndex: "10",
  },
  fullButton: {
    position: "fixed",
    bottom: "12%",
    right: "8%",
    zIndex: "10",
  },
  switchButton: {
    "&:focus": {
      borderColor: "#EE7B5A",
    },
    fontWeight: "bold",
    fontSize: "10px",
    width: "65vw",
    padding: "10px !important",
    borderRadius: "60px",
    borderColor: "#EE7B5A",
    zIndex: 10,
    position: "fixed",
    bottom: "11%",
    left: "18%",
  },
  "@media (max-width: 915px) and (orientation:landscape)": {
    switchButton: {
      "&:focus": {
        borderColor: "#EE7B5A",
      },
      fontWeight: "bold",
      fontSize: "10px",
      width: "35vw",
      padding: "10px !important",
      borderRadius: "60px",
      borderColor: "#EE7B5A",
      top: "74%",
      left: "35%",
      right: 0,
      zIndex: 10,
      align: "bottom",
    },
    fullButton: {
      paddingLeft: "5px",
      top: "85%",
      position: "fixed",
      align: "bottom",
      right: "20%",
    },
    infoButton: {
      position: "fixed",
      paddingRight: "5px",
      align: "bottom",
      top: "85%",
      left: "20%",
    },
  },
}));

const Actions = ({
  selectedDish,
  fullScreen,
  setFullScreen,
  show,
  setShow,
  setDisableZoom,
  disableZoom,
  isSpin,
}) => {
  const { t } = useTranslation();
  const [trigger, setTrigger] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleZoom = () => {
    setDisableZoom(!disableZoom);
  };
  const { branch } = useSelector((state) => state.branch);
  const setting = branch?.setting || selectedDish?.setting;
  const productToOrder = () => {
    const order = {
      id: selectedDish.id,
      image: selectedDish.image,
      name: selectedDish.title,
      quantity: 1,
      totalAmount: selectedDish.price,
      totalItemAmount: 0,
      discountPercent: selectedDish.discount_percent,
      items: [],
    };
    dispatch(addOrder(order));
    navigate(-1);
    dispatch(
      openInfo({
        type: "success",
        message: "Orden añadida con éxito",
      }),
    );
  };

  //    <Grid item className={classes.primaryButton} onClick={productToOrder}>
  //      Agregar
  //    </Grid>
  //    <Grid item onClick={() => setShow(!show)}>
  //      <img src={selectedDish && show ? trash : viewProduct3d} alt="share"/>
  //    </Grid>
  //        <Grid item onClick={() => setDisableZoom(!disableZoom)}>
  //          <img src={disableZoom ? zoomAbled : zoomDisabled} alt="share" style={{ width: '10vw' }}/>
  //        </Grid>
  //
  const customColor =
    setting?.background_color_main_button ||
    "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)";

  const activeButtonStyle = {
    color: "white",
    fontWeight: "bold",
    background: "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)",
  };

  const chipColor = {
    color: `${setting?.text_color_main_button || "white"} !important`,
    background: `${
      setting?.background_color_main_button ||
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)"
    } !important`,
  };

  const activateAR = () => {
    const modelViewer = document.querySelector("model-viewer");
    if (modelViewer) {
      modelViewer.activateAR();
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-around"
      sx={{ zIndex: -1, width: "100vw" }}
    >
      <Grid item className={classes.infoButton}>
        <InfoButton
          trigger={trigger}
          setTrigger={setTrigger}
          customColor={customColor}
        />
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          className={classes.switchButton}
          style={activeButtonStyle}
          sx={{
            visibility: fullScreen || isSpin ? "hidden" : "visible",
            fontFamily: setting?.general_font,
            ...chipColor,
          }}
          onClick={() => activateAR()}
        >
          {t("product3D.viewAR")}
        </Button>
      </Grid>
      <Grid
        item
        className={classes.fullButton}
        onClick={() => setFullScreen(!fullScreen)}
      >
        {fullScreen ? (
          <NormalScreenPicture fill={customColor} />
        ) : (
          <FullScreenPicture fill={customColor} />
        )}
      </Grid>
    </Grid>
  );
};

export default Actions;
