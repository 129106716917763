import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Slider,
  Typography,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import { setFilters } from "../reducers/filter";
import { setLoading } from "../reducers/loading";
import diet from "../images/diet.svg";
import product3d from "../images/3d.svg";
import outstanding from "../images/outstanding.svg";
import discount from "../images/discount.svg";
import pricing from "../images/pricing.svg";
import { readLeftToRight } from "../services/languages.js";

const useStyles = makeStyles(() => ({
  root: {
    padding: "20px",
  },
  list: {
    height: "80vh !important",
    overflow: "auto",
    width: "90vw !important",
  },
  activeChip: {
    height: "25px !important",
    borderRadius: "5px !important",
    margin: "5px !important",
    fontSize: "10px !important",
    color: "white !important",
    background:
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important",
    border: "1px solid transparent !important",
  },
  chip: {
    height: "25px !important",
    borderRadius: "5px !important",
    border: "1px solid #666666 !important",
    margin: "5px !important",
    fontSize: "10px !important",
    color: "black",
    background: "white !important",
  },
  bottomButtons: {
    position: "absolute",
    bottom: "0px !important",
    marginLeft: "-5%",
    marginBottom: "5%",
  },
  clear: {
    padding: "10px 20px !important",
    color: "#EE7B5A !important",
    borderRadius: "50px !important",
    border: "1px solid #E20613 !important",
    textTransform: "capitalize !important",
    fontWeight: "bold !important",
  },
  apply: {
    padding: "10px 20px !important",
    color: "white !important",
    background:
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important",
    borderRadius: "50px !important",
    textTransform: "capitalize !important",
    fontWeight: "bold !important",
  },
  "@media (max-width: 915px) and (orientation:landscape)": {
    bottomButtons: {
      position: "absolute",
      bottom: "0px !important",
      marginLeft: "-5%",
      marginBottom: "2%",
    },
  },
}));

const marks = [
  {
    value: 0,
    label: "$0",
  },
  {
    value: 20,
    label: "$20",
  },
  {
    value: 37,
    label: "$37",
  },
  {
    value: 100,
    label: "$100",
  },
];

const Filter = () => {
  const { filters } = useSelector((state) => state.filter.filter);
  const { branch } = useSelector((state) => state.branch);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { setting, allergens } = branch;
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const initialState = {
    diet: [],
    product3d: false,
    featuredDishes: false,
    withDiscount: false,
    pricing: {
      min: 0,
      max: null,
      asc: false,
      desc: false,
    },
  };
  const [currentFilters, setCurrentFilters] = useState(initialState);
  const diets = allergens ? allergens : [];

  const setDiet = (diet) => {
    const diets = [...currentFilters.diet];
    if (currentFilters.diet.includes(diet)) {
      const index = diets.indexOf(diet);
      diets.splice(index, 1);
    } else {
      diets.push(diet);
    }
    setCurrentFilters({ ...currentFilters, diet: diets });
  };

  const Diet = () => {
    return diets.map((diet) => {
      const isActive = currentFilters.diet.includes(diet.id);
      return (
        <Grid item key={diet.id}>
          <Chip
            label={diet.name}
            className={isActive ? classes.activeChip : classes.chip}
            sx={{ fontFamily: setting?.general_font }}
            onClick={() => setDiet(diet.id)}
          />
        </Grid>
      );
    });
  };
  useEffect(() => {
    dispatch(setLoading(true));
    if (allergens) {
      dispatch(setLoading(false));
    }
  }, [allergens]);

  useEffect(() => {
    if (filters) {
      setCurrentFilters(filters);
    }
  }, [filters]);

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      className={classes.root}
      sx={{ fontFamily: setting?.general_font }}
    >
      <Grid item>
        <List className={classes.list} dir={readLeftToRight(language)}>
          <ListItem>
            <ArrowBack
              fontSize="20"
              onClick={() => {
                navigate(-1);
              }}
            />
          </ListItem>
          <ListItem>
            <Typography sx={{ fontFamily: setting?.general_font }}>
              {t("filter.title")}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <img src={diet} alt="diet" />
              </Grid>
              <Grid item>{t("filter.dietTypes")}</Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem>
            <Grid container>
              <Diet />
            </Grid>
          </ListItem>
          <Divider />
          <ListItem>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={10}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <img src={product3d} alt="3d" />
                  </Grid>
                  <Grid item>{t("filter.dishes3D")}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Checkbox
                  checked={currentFilters.product3d}
                  onChange={() =>
                    setCurrentFilters({
                      ...currentFilters,
                      product3d: !currentFilters.product3d,
                    })
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: "#FF4B55",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <img src={outstanding} alt="outstanding" />
                  </Grid>
                  <Grid item>{t("filter.featuredDishes")}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Checkbox
                  checked={currentFilters.featuredDishes}
                  onChange={() =>
                    setCurrentFilters({
                      ...currentFilters,
                      featuredDishes: !currentFilters.featuredDishes,
                    })
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: "#FF4B55",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <img src={discount} alt="discount" />
                  </Grid>
                  <Grid item>{t("filter.withDiscount")}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Checkbox
                  checked={currentFilters.withDiscount}
                  onChange={() =>
                    setCurrentFilters({
                      ...currentFilters,
                      withDiscount: !currentFilters.withDiscount,
                    })
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: "#FF4B55",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider style={{ display: "none" }} />
          <ListItem style={{ display: "none" }}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <img src={pricing} alt="pricing" />
              </Grid>
              <Grid item>Precio máximo</Grid>
            </Grid>
          </ListItem>
          <Divider style={{ display: "none" }} />
          <ListItem style={{ display: "none" }}>
            <Slider
              aria-label="Custom marks"
              defaultValue={20}
              step={10}
              valueLabelDisplay="auto"
              marks={marks}
              style={{
                color: "#FF4B55",
              }}
            />
          </ListItem>
          <ListItem style={{ display: "none" }}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <img src={pricing} alt="pricing" />
              </Grid>
              <Grid item>Ordenar por precio</Grid>
            </Grid>
          </ListItem>
          <Divider style={{ display: "none" }} />
          <ListItem style={{ display: "none" }}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Chip
                  label="De menor a mayor"
                  className={
                    currentFilters.pricing.asc
                      ? classes.activeChip
                      : classes.chip
                  }
                  onClick={() =>
                    setCurrentFilters({
                      ...currentFilters,
                      pricing: {
                        ...currentFilters.pricing,
                        asc: !currentFilters.pricing.asc,
                        desc: false,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item>
                <Chip
                  label="De mayor a menor"
                  className={
                    currentFilters.pricing.desc
                      ? classes.activeChip
                      : classes.chip
                  }
                  onClick={() =>
                    setCurrentFilters({
                      ...currentFilters,
                      pricing: {
                        ...currentFilters.pricing,
                        desc: !currentFilters.pricing.desc,
                        asc: false,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent="center"
          className={classes.bottomButtons}
          spacing={window.screen.width >= 1344 ? 2 : 0}
        >
          <Grid item xs={window.screen.width >= 1344 ? 0 : 4}>
            <Button
              className={classes.clear}
              onClick={() => dispatch(setFilters(initialState))}
              sx={{ fontFamily: setting?.general_font }}
            >
              {t("filter.clear")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.apply}
              sx={{ fontFamily: setting?.general_font }}
              onClick={() => {
                dispatch(setFilters(currentFilters));
                navigate(-1);
              }}
            >
              {t("filter.apply")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filter;
