const PRTranslation = {
  pt: {
    translation: {
      popUp: {
        title: "Ajude a usar a Realidade Aumentada.",
        text: "1. Aponte sua câmera para uma mesa ou superfície iluminada.",
        text2:
          "2.  Movimente seu celular lentamente em círculos até que o desenho da mão desapareça.",
        text3:
          "3. Use um dedo para mover o prato e dois para girá-lo. Se você aproximar a câmera do prato, verá com mais detalhes!",
      },
      buttons: {
        accept: "Aceitar",
      },
      sidebar: {
        languages: {
          title: "Línguas",
          es: "Espanhol",
          en: "Inglês",
          pt: "Português",
          it: "Italiano",
          zh: "chinês",
          ar: "Árabe",
          fr: "Francês",
          he: "Hebraico",
          ru: "Russo",
          ja: "Japonês",
          de: "Alemão",
        },
      },
      index: {
        allCategories: "ver tudo",
        searchPlaceholder: "O que você quer pedir?",
        filter: "Filtro",
        viewMenu3D: "VER MENU 3D",
        moreInformation: "Mais informações",
        snackbar:
          "Deslize o dedo da esquerda para a direita sobre um prato para traduzi-lo para a língua do garçom",
      },
      category: {
        product: {
          dish3d: "Prato 3D",
          outstanding: "Excepcional",
          extras: "Adicionais",
          dish360: "Prato 360",
          dishVideo: "Video",
        },
      },
      product: {
        see360: "VER EM 360",
        see3D: "VEJA EM 3D",
        seeVideo: "VER VIDEO",
        ar: "REALIDADE AUMENTADA (AR)",
        dietTypes: "Tipos de dieta - Especificações",
        waiterButton: "TRADUZIR PRATO PARA O GARÇOM",
      },
      product3D: {
        viewAR: "VEJA EM REALIDADE AUMENTADA (AR)",
        view3D: "VEJA EM 3D",
      },
      filter: {
        title: "Filtro",
        dietTypes: "Tipos de dieta - Especificações",
        dishes3D: "Pratos 3D / 360 / Video",
        clear: "Limpar",
        apply: "Aplicar",
        featuredDishes: "Produtos Destacados",
        withDiscount: "Com desconto",
      },
      error: {
        title: "Oh!",
        subtitle: "algo deu errado",
        caption:
          "Certifique-se de que o QR ou o link pertence a um estabelecimento associado ao Cartaar ou tente novamente.",
      },
      about: {
        daysAndSchedules: "Dias e Horários",
        location: "Localização",
        coverCharge: "Taxa de Cobertura",
        socialNetworks: "Redes Sociais",
      },
      days: {
        0: "Segunda-feira",
        1: "Terça-feira",
        2: "Quarta-feira",
        3: "Quinta-feira",
        4: "Sexta-feira",
        5: "Sábado",
        6: "Domingo",
      },
    },
  },
};

export default PRTranslation;
