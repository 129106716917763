const ESTranslation = {
  es: {
    translation: {
      popUp: {
        title: "Ayuda para usar Realidad Aumentada",
        text: "1. Apunta tu cámara hacia una mesa o superficie iluminada.",
        text2:
          "2. Mueve tu celular lentamente en círculos hasta que desaparezca el dibujo de la mano.",
        text3:
          "3. Utiliza un dedo para mover el plato y dos para rotarlo. Si acercas la cámara al plato, lo verás con más detalle!.",
      },
      buttons: {
        accept: "Aceptar",
      },
      sidebar: {
        languages: {
          title: "Idiomas",
          es: "Español",
          en: "Inglés",
          pt: "Portugués",
          it: "Italiano",
          zh: "Chino",
          ar: "Árabe",
          fr: "Francés",
          he: "Hebreo",
          ru: "Ruso",
          ja: "Japonés",
          de: "Alemán",
        },
      },
      index: {
        allCategories: "Ver Todas",
        searchPlaceholder: "Qué querés pedir?",
        filter: "Filtrar",
        viewMenu3D: "VER MENÚ 3D",
        moreInformation: "Más Información",
        snackbar:
          "Deslizá un dedo de izquierda a derecha sobre un plato para traducirlo al idioma del mozo",
      },
      category: {
        product: {
          dish3d: "Plato 3D",
          outstanding: "Destacado",
          extras: "Adicionales",
          dish360: "Plato 360",
          dishVideo: "Video",
        },
      },
      product: {
        see360: "VER EN 360",
        see3D: "VER EN 3D",
        seeVideo: "VER VIDEO",
        ar: "REALIDAD AUMENTADA (AR)",
        dietTypes: "Tipos de dieta - Especificaciones",
        waiterButton: "TRADUCIR PLATO AL MOZO",
      },
      product3D: {
        viewAR: "VER EN REALIDAD AUMENTADA (AR)",
        view3D: "VER EN 3D",
      },
      filter: {
        title: "Filtrar",
        dietTypes: "Tipos de dieta - Especificaciones",
        dishes3D: "Platos 3D / 360 / Video",
        clear: "Limpiar",
        apply: "Aplicar",
        featuredDishes: "Productos Destacados",
        withDiscount: "Con descuento",
      },
      error: {
        title: "Oops!",
        subtitle: "Algo salió mal",
        caption:
          "Asegurate de que el QR o el link pertenezca a un establecimiento asociado a Cartear o inténtalo de nuevo.",
      },
      about: {
        daysAndSchedules: "Días y Horarios",
        location: "Ubicación",
        coverCharge: "Precio cubierto",
        socialNetworks: "Redes sociales",
      },
      days: {
        0: "Lunes",
        1: "Martes",
        2: "Miércoles",
        3: "Jueves",
        4: "Viernes",
        5: "Sábado",
        6: "Domingo",
      },
    },
  },
};
export default ESTranslation;
