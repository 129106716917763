import * as React from "react";
import { Box, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Description from "./Description";
import Diet from "./Diet";
import Parents from "./Parents";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { setLoading, setRefresh } from "../../reducers/loading";
import Chips from "./Chips";
import { Height } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  close: {
    position: "absolute",
    fontSize: 50,
    color: "white",
    right: 0,
    cursor: "pointer",
    margin: 20,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
  },
  box: {
    height: "700px",
  },
  image: {
    width: 600,
    aspectRatio: "1/1",
    borderTopLeftRadius: "18px",
    borderTopRightRadius: "18px",
  },
  listContainer: {
    overflowY: "auto",
    width: 600,
  },
  chips: {
    position: 'relative',
    height: '30px',
    marginTop: '-50px',
    marginBottom: '50px',
    display: 'flex',
  }
}));

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  border: "2px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
};

export default function ShowModal({
  product,
  branch,
  setting,
  online,
  order,
  setOrder,
}) {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Modal
        open
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: "hidden !important",
          height: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "round",
          backgroundImage: `url(${branch?.setting?.background_general_image})`,
        }}
      >
        <Box sx={style} className={classes.box}>
          <CloseIcon className={classes.close} onClick={() => navigate(-1)} />
          {product.image && (
            <Grid xs={12}>
              <img src={product.image} className={classes.image} />
            </Grid>
          )}
          <Grid className={classes.chips}>
            <Chips setting={setting} product={product}/>
          </Grid>
          <Grid xs={12} className={classes.listContainer}>
            <List
              className="show-ul-inside"
              style={{ height: "250px", overflowX: "hidden" }}
            >
              <Description product={product} branch={branch} />
              <Divider />
              {product?.allergens?.length > 0 && (
                <Diet product={product} online={online} setting={setting} />
              )}
              <Parents
                parentsRelationship={product.accompaniments}
                order={order}
                branch={branch}
              />
              <Parents
                parentsRelationship={product.toppings}
                order={order}
                setOrder={setOrder}
                branch={branch}
              />
              <Parents
                parentsRelationship={product.variants}
                order={order}
                setOrder={setOrder}
                branch={branch}
              />
            </List>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
