import React, { forwardRef } from 'react';
import {
  Box,
  Slide
} from '@mui/material';
import ListOrders from '../menu/orders/ListOrders';

const InfoOrder = forwardRef((props, ref) => {
  const { order, branch } = props;

  if(!order) {
    return <></>
  }

  return (
    <Box sx={{ position: 'fixed !important'}} ref={ref}>
      <Slide direction="up" in={order.open} mountOnEnter unmountOnExit>
        <ListOrders branch={branch}/>
      </Slide>
    </Box>
  )
})

export default InfoOrder;
