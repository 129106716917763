const ENTranslation = {
  en: {
    translation: {
      popUp: {
        title: "Help to use Augmented Reality",
        text: "1. Point your camera at a table or light surface.",
        text2:
          "2. Move your cell phone slowly in circles until the drawing of the hand disappears.",
        text3:
          "3. Use one finger to move the dish and two to rotate it. If you bring the camera closer to the dish, you will see it in more detail!",
      },
      buttons: {
        accept: "Accept",
      },
      sidebar: {
        languages: {
          title: "Languages",
          es: "Spanish",
          en: "English",
          pt: "Portuguese",
          it: "Italian",
          zh: "Chinese",
          ar: "Arabic",
          fr: "French",
          he: "Hebrew",
          ru: "Russian",
          ja: "Japanese",
          de: "German",
        },
      },
      index: {
        allCategories: "See all",
        searchPlaceholder: "What do you want to order?",
        filter: "Filter",
        viewMenu3D: "SEE 3D MENU",
        moreInformation: "More Information",
        snackbar:
          "Swipe a finger from left to right over a dish to translate it to the waiter's language",
      },
      category: {
        product: {
          dish3d: "3D dish",
          outstanding: "Outstanding",
          extras: "Extras",
          dish360: "360 dish",
          dishVideo: "Video",
        },
      },
      product: {
        see360: "SEE IN 360",
        see3D: "SEE IN 3D",
        seeVideo: "SEE VIDEO",
        ar: "AUGMENTED REALITY (AR)",
        dietTypes: "Diet types - Specifications",
        waiterButton: "TRANSLATE DISH FOR WAITER",
      },
      product3D: {
        viewAR: "SEE IN AUGMENTED REALITY (AR)",
        view3D: "SEE IN 3D",
      },
      filter: {
        title: "Filter",
        dietTypes: "Diet types - Specifications",
        dishes3D: "3D / 360 dishes / Video",
        clear: "Clean",
        apply: "Apply",
        featuredDishes: "Featured Products",
        withDiscount: "With discount",
      },
      error: {
        title: "Oh!",
        subtitle: "Something went wrong",
        caption:
          "Make sure that the QR or the link belongs to an establishment associated with Cartear or try again.",
      },
      about: {
        daysAndSchedules: "Days and Schedules",
        location: "Location",
        coverCharge: "Cover charge",
        socialNetworks: "Social networks",
      },
      days: {
        0: "Monday",
        1: "Tuesday",
        2: "Wednesday",
        3: "Thursday",
        4: "Friday",
        5: "Saturday",
        6: "Sunday",
      },
    },
  },
};

export default ENTranslation;
