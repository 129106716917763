import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ESTranslation from "./es/es.js";
import ENTranslation from "./en/en.js";
import PTTranslation from "./pt/pt.js";
import ITTranslation from "./it/it.js";
import ZHTranslation from "./zh/zh.js";
import ARTranslation from "./ar/ar.js";
import FRTranslation from "./fr/fr.js";
import HETranslation from "./he/he.js";
import RUTranslation from "./ru/ru.js";
import JATranslation from "./ja/ja.js";
import DETranslation from "./de/de.js";

let language = navigator.language.split("-")[0];
const supportedLanguages = [
  "es",
  "en",
  "pt",
  "zh",
  "ru",
  "ar",
  "he",
  "de",
  "ja",
  "it",
  "fr",
];

language = supportedLanguages.includes(language) ? language : "es";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ...ESTranslation,
      ...ENTranslation,
      ...PTTranslation,
      ...ITTranslation,
      ...ZHTranslation,
      ...ARTranslation,
      ...FRTranslation,
      ...HETranslation,
      ...RUTranslation,
      ...JATranslation,
      ...DETranslation,
    },
    fallbackLng: language,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
