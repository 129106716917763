import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, 0)",
        zIndex: 10000000,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
