import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DishCard from "./DishCard";
import "../../styles/viewer/dish.css";

const DishesCard = ({ dishes, currentDish, setSelectedDish }) => {
  if (!dishes) {
    return <></>;
  }

  const dishesWith3d = dishes.filter(
    (dish) => dish.url_3d_web || dish.url_3d_android || dish.url_3d_ios,
  );

  return (
    <Grid container spacing={2} className="viewer-dish-root">
      {dishesWith3d.map((dish) => (
        <DishCard
          dish={dish}
          key={dish.id}
          currentDish={currentDish}
          setSelectedDish={setSelectedDish}
        />
      ))}
    </Grid>
  );
};

export default DishesCard;
