import { getAuth, signInAnonymously } from "firebase/auth";
import { firebaseApp } from "../index.js";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { getStorage, ref, listAll } from "firebase/storage";
import axios from "axios";

export const getModelViewerUrl = async (dish) => {
  if (dish) {
    let URL3DWeb = dish.url_3d_web;
    if (URL3DWeb && URL3DWeb.includes("gs://cartear-f8ceb.appspot.com/")) {
      const MODEL_PATH = URL3DWeb?.split("gs://cartear-f8ceb.appspot.com/")[1];

      const STORAGE = firebaseApp.storage();
      const STORAGE_REF = STORAGE.ref();
      const TANG_REF = STORAGE_REF.child(MODEL_PATH);

      const auth = getAuth();
      let url;
      let response = await signInAnonymously(auth);
      if (response) {
        try {
          url = await TANG_REF.getDownloadURL();
        } catch (err) {
          console.log(err);
        }
      }
      return url;
    }
  }
  return;
};

export const getModelViewerUrlV2 = async (dishUrl) => {
  if (dishUrl) {
    let URL3DWeb = dishUrl;
    if (URL3DWeb && URL3DWeb.includes("gs://cartear-f8ceb.appspot.com/")) {
      const MODEL_PATH = URL3DWeb?.split("gs://cartear-f8ceb.appspot.com/")[1];

      const STORAGE = firebaseApp.storage();
      const STORAGE_REF = STORAGE.ref();
      const TANG_REF = STORAGE_REF.child(MODEL_PATH);

      const auth = getAuth();
      let url;
      let response = await signInAnonymously(auth);
      if (response) {
        try {
          url = await TANG_REF.getDownloadURL();
        } catch (err) {
          console.log(err);
        }
      }
      return url;
    }
  }
  return;
};

export const anonymousSignIn = (auth) => {
  signInAnonymously(auth)
    .then()
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
      // ...
    });
};

export const arrivesCartearUser = (props) => {
  const { user, branchId, tableId } = props;
  const token = `Bearer ${user.accessToken}`;
  const headers = {
    headers: {
      Authorization: token,
    },
  };
  axios
    .put("users/arrives_branch", { branchId, table_id: tableId }, headers)
    .then((res) => {
      if (res.data) {
        // dispatch(loginIn(user));
        window.localStorage.setItem("cartearUser", JSON.stringify(user));
        window.location.reload();
      }
    })
    .catch((err) => console.log(err));
};
