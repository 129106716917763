import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import { Box, Tab } from "@mui/material";
import { TabList } from "@mui/lab";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  box: {
    borderBottom: 1,
    borderColor: "divider",
    overflow: "auto",
  },
}));

const TabHeader = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    branch: { setting },
  } = useSelector((state) => state.branch);
  const { categories, tab, setTab } = props;

  return (
    <Box className={classes.box} ref={ref}>
      <TabList
        onChange={(_, v) => {
          setTab(v);
        }}
        variant="scrollable"
        style={{
          borderBottom: "2px solid rgba(0, 0, 0, 0.08)",
        }}
        TabIndicatorProps={{
          style: {
            background: "#E20613",
            textTransform: "capitalize !important",
          },
        }}
      >
        {categories.map((category, index) => (
          <Tab
            label={category.name}
            value={String(index)}
            style={{
              textTransform: "capitalize",
              fontFamily: setting?.categories_font,
              fontStyle: "normal",
              fontWeight: index === Number(tab) ? "bold" : "500",
              color:
                index === Number(tab)
                  ? setting?.text_color_viewer || "black"
                  : setting?.text_color_viewer || "#666666",
            }}
            key={`tab ${index}`}
          />
        ))}
      </TabList>
    </Box>
  );
});

export default TabHeader;
