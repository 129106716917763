import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Chip, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { isSpin, isVideo, is3d } from "../../services/url";

const Chips = ({ setting, product }) => {
  const { canActivateAR } = useSelector((state) => state.info);
  const openAR = () => window.localStorage.setItem("openAR", true);
  const { t } = useTranslation();
  const is360 = isSpin(product?.url_3d_web);
  const is_video = isVideo(product?.url_3d_web);
  const is3D = is3d(product?.url_3d_web);
  const canView3d = product.url_3d_web;
  const location = useLocation();

  const chipColor = {
    color: `${setting?.text_color_main_button || "white"} !important`,
    cursor: 'pointer',
    background: `${
      setting?.background_color_main_button ||
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%)"
    } !important`,
    fontFamily: setting?.general_font,
  };

  const label = () => {
    let l;
    if (is3D) {
      l = t("product.see3D");
    } else if (is360) {
      l = t("product.see360");
    } else if (is_video) {
      l = t("product.seeVideo");
    }
    return l;
  };

  return (
    <>
      {canView3d && (
        <Grid
          container
          className="show-header-container-chip"
          alignItems="end"
          justifyContent="center"
          style={{
            position: product?.image ? "inherit" : "absolute",
            top: 30,
            left: 0,
          }}
          spacing={3}
        >
          <Grid item>
            <Chip
              label={label()}
              className="show-header-chip3d"
              component={Link}
              sx={chipColor}
              to={`${location.pathname}/product_viewer`}
            />
          </Grid>
          {canActivateAR && is3D && (
            <Grid item>
              <Chip
                label={t("product.ar")}
                className="show-header-chip3d"
                component={Link}
                sx={chipColor}
                to={`${location.pathname}/product_viewer`}
                onClick={() => openAR()}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default Chips;
