import { createSlice } from "@reduxjs/toolkit";

export const infoReducer = createSlice({
  name: "info",
  initialState: {
    info: {
      open: false,
      type: "error",
      message: "Hubo un error, intente nuevamente",
    },
    order: {
      open: false,
      type: "orders",
    },
    showSnackbar: true,
    validToken: false,
    canActivateAR: null,
    refreshToken: false,
    lastDishScrollPosition: 0,
    currentMode: "3D",
    openSidebar: false,
    viewFromIndex: false,
    firstOpen: true,
    showInvalidMessage: false,
    selectedCategoryPosition: {
      position: "0",
      categoryId: null,
    },
  },
  reducers: {
    openInfo: (state, action) => {
      state.info.open = true;
      state.info.type = action.payload.type;
      state.info.message = action.payload.message;
    },
    closeInfo: (state, _action) => {
      state.info.open = false;
    },
    openOrder: (state, action) => {
      state.order.open = true;
      if (action.payload?.type) {
        state.order.type = action.payload.type;
      } else {
        state.order.type = "orders";
      }
    },
    closeOrder: (state, _action) => {
      state.order.open = false;
    },
    setValidToken: (state, action) => {
      state.validToken = action.payload;
    },
    setCanActivateAR: (state, action) => {
      state.canActivateAR = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setLastDishScrollPosition: (state, action) => {
      state.lastDishScrollPosition = action.payload;
    },
    setOpenSidebar: (state, action) => {
      state.openSidebar = action.payload;
    },
    setCurrentMode: (state, action) => {
      state.currentMode = action.payload;
    },
    setViewFromIndex: (state, action) => {
      state.viewFromIndex = action.payload;
    },
    setSelectedCategoryPosition: (state, action) => {
      state.selectedCategoryPosition = action.payload;
    },
    setFirstOpen: (state, action) => {
      state.firstOpen = action.payload;
    },
    setShowInvalidMessage: (state, action) => {
      state.showInvalidMessage = action.payload;
    },
    setShowSnackbar: (state, _action) => {
      state.showSnackbar = false;
    },
  },
});

export const {
  openInfo,
  closeInfo,
  openOrder,
  closeOrder,
  setValidToken,
  setCanActivateAR,
  setCanGoBack,
  setRefreshToken,
  setLastDishScrollPosition,
  setOpenSidebar,
  setCurrentMode,
  setViewFromIndex,
  setSelectedCategoryPosition,
  setFirstOpen,
  setShowInvalidMessage,
  setShowSnackbar,
} = infoReducer.actions;

export default infoReducer.reducer;
