import React from "react";
import { useSelector } from "react-redux";
import { Chip, Divider, Grid, ListItem, Typography } from "@mui/material";
import ItemsCards from "./ItemsCards";
import "../../styles/show/parents.css";

const Parents = ({
  branch,
  parentsRelationship,
  waiterRelationship,
  waiterParentKey,
  order,
  setOrder,
}) => {
  const parents = parentsRelationship.filter(
    (parent) => parent.items.length > 0,
  );
  const { setting } = branch;
  const customColor = {
    color: `${setting?.general_text_color || "black"} !important`,
    fontFamily: setting?.general_font,
  };

  if (parents.length === 0) {
    return <></>;
  }

  return parents.map((parent, index) => (
    <React.Fragment key={`${parent.id}${index}`}>
      <ListItem className="parent-item-container">
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography
                  className="parent-title"
                  sx={{
                    ...customColor,
                    fontFamily: setting?.dishes_description_font,
                  }}
                >
                  {parent.name}
                </Typography>
              </Grid>
              <Grid item>
                {branch?.prefix === "D" && parent.is_required && (
                  <Chip className="parent-chip" label="Obligatorio" />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="parent-subtitle">
            {branch?.prefix === "D" &&
              parent.limit > 0 &&
              `Selecciona máximo ${parent.limit} opciones`}
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
      <ItemsCards
        parent={parent}
        order={order}
        setOrder={setOrder}
        branch={branch}
        waiterParent={waiterRelationship}
        waiterParentKey={waiterParentKey}
      />
    </React.Fragment>
  ));
};

export default Parents;
