const JATranslation = {
  ja: {
    translation: {
      popUp: {
        title: "拡張現実の使用に関するヘルプ",
        text: "1. カメラをテーブルまたは明るい表面に向けます。 ",
        text2:
          "2. 手の形が消えるまで、携帯電話を円を描くようにゆっくりと動かします。",
        text3:
          "3. 1 本の指で皿を動かし、2 本の指で回転させます。 お皿にカメラを近づけると、もっとくわしく見えます！",
      },
      buttons: {
        accept: "受け入れる",
      },
      sidebar: {
        languages: {
          title: "言語",
          es: "スペイン語",
          en: "英語",
          pt: "ポルトガル語",
          it: "イタリア語",
          zh: "中国語",
          ar: "アラビア語",
          fr: "フランス語",
          he: "ヘブライ語",
          ru: "ロシア",
          ja: "日本",
          de: "ドイツ人",
        },
      },
      index: {
        allCategories: "すべてを見る",
        searchPlaceholder: "あなたは何を注文したいのですか？",
        filter: "フィルター",
        viewMenu3D: "3D メニューを見る",
        moreInformation: "もっと詳細",
        snackbar:
          "ウェイターの言語に翻訳するために、お皿に左から右に指をスワイプします。",
      },
      category: {
        product: {
          dish3d: "3Dプレート",
          outstanding: "未解決の",
          extras: "エクストラ",
          dish360: "360度料理",
          dishVideo: "ビデオ",
        },
      },
      product: {
        see360: "360度で見る",
        see3D: "3Dで見る",
        seeVideo: "ビデオを見る",
        ar: "拡張現実 (AR)",
        dietTypes: "食事の種類 - 仕様",
        waiterButton: "料理をウェイターに翻訳",
      },
      product3D: {
        viewAR: "拡張現実 (AR) で見る",
        view3D: "3Dで見る",
      },
      filter: {
        title: "フィルター",
        dietTypes: "食事の種類 - 仕様",
        dishes3D: "3D / 360 料理/ ビデオ",
        clear: "消去",
        apply: "申し込み",
        featuredDishes: "注目商品",
        withDiscount: "割引あり",
      },
      error: {
        title: "おお!",
        subtitle: "エラーが発生しました",
        caption:
          "QR またはリンクが Cartear に関連付けられた施設に属していることを確認するか、もう一度お試しください。",
      },
      about: {
        daysAndSchedules: "日とスケジュール",
        location: "位置",
        coverCharge: "カバーチャージ",
        socialNetworks: "ソーシャルネットワーク",
      },
      days: {
        0: "月曜日",
        1: "火曜日",
        2: "水曜日",
        3: "木曜日",
        4: "金曜日",
        5: "土曜日",
        6: "日曜日",
      },
    },
  },
};

export default JATranslation;
