import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import Chips from "./Chips";
import BadgeOrders from "../utils/BadgeOrders";
import backgroundPlaceholder from "../../images/backgroundPlaceholder.svg";
import "../../styles/show/header.css";

const Header = (props) => {
  const { branch, product } = props;
  const { canActivateAR } = useSelector((state) => state.info);
  const { setting } = branch;
  const mode = window.localStorage.getItem("mode");
  const [background, setBackground] = useState(product.image);
  const navigate = useNavigate();

  //    <Grid item className={classes.headerBadge}>
  //      { mode === 'T' && <BadgeOrders orders={branch.orders}/> }
  //    </Grid>
  //

  return (
    <Grid
      container
      className="show-header"
      style={{
        backgroundImage: `url(${background})`,
        width: product?.image ? "100vw" : "0px",
        aspectRatio: "1 / 1",
      }}
      justifyContent="space-around"
    >
      <Grid
        item
        xs={11}
        sx={{
          marginTop: product?.image ? "30px" : "0px",
        }}
      >
        <ArrowBack
          fontSize="20"
          onClick={() => {
            navigate(-1, { replace: true });
          }}
          className="show-header-back-arrow"
          sx={{
            color: setting?.background_color_main_button || "black",
            position: product?.image ? "static" : "absolute",
            zIndex: 10000,
          }}
        />
      </Grid>
      <Chips setting={setting} product={product} />
    </Grid>
  );
};

export default Header;
