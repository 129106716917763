import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Popup from "../utils/PopUp";
import { ReactComponent as Info } from "../../images/info.svg";

function InfoButton({ trigger, setTrigger, customColor }) {
  return (
    <Grid>
      <Grid
        alignItems="center"
        justifyContent="space-around"
        sx={{ zIndex: 1000000000 }}
      >
        <Grid item>
          <Info
            style={{ width: "6vw" }}
            fill={customColor}
            onClick={() => setTrigger(true)}
          />
          <Popup trigger={trigger} setTrigger={setTrigger}></Popup>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InfoButton;
