import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Paper } from "@mui/material";
import { closeOrder } from "../../../reducers/info";
import { openInfo } from "../../../reducers/info";
import { emptyOrders } from "../../../reducers/branch";
import { ordersToOrderedOrders } from "../../../reducers/branch";

const useStyles = makeStyles(() => ({
  primaryButton: {
    padding: "5px 40px !important",
    color: "white !important",
    background:
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important",
    borderRadius: "50px !important",
    textTransform: "capitalize !important",
    fontWeight: "bold !important",
  },
  bottom: {
    position: "sticky !important",
    bottom: 0,
    width: "100vw",
    height: "5vh",
    marginTop: "-5vh",
    boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "15px 15px 0px 0px !important",
    background: "white",
    padding: "15px 0px",
    zIndex: 10000,
  },
  amount: {
    color: "#E20613",
    fontFamily: "Rubik, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "14px",
    textAlign: "center",
  },
}));

const Actions = ({ branch, currentAmount, orderInfo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const splitPath = location.pathname.split("/");
  const TOKEN = 1;
  const TOKEN_VALUE = 2;
  const basePath = splitPath[TOKEN] + "/" + splitPath[TOKEN_VALUE];
  const { orders } = branch;
  const orderToPayload = (order) => {
    const payload = [
      {
        consumable_id: order.id,
        consumable_type: "Dish",
        quantity: order.quantity,
      },
    ];

    order.items.forEach((item) =>
      payload.push({
        consumable_id: item.id,
        consumable_type: "Item",
        quantity: item.quantity,
      }),
    );

    return payload;
  };

  const handleConfirm = () => {
    const payload = {
      order: {
        table_id: branch.tableId,
        order_consumables_attributes: orders
          .map((order) => orderToPayload(order))
          .flat(),
      },
    };

    axios
      .post("orders", payload)
      .then((res) => {
        if (res.data) {
          dispatch(ordersToOrderedOrders());
          dispatch(closeOrder());
          dispatch(
            openInfo({
              type: "success",
              message:
                "Su orden ha sido recibida por la cocina, espera a la entrega de la orden",
            }),
          );
          navigate(basePath);
        }
      })
      .catch((err) => {
        dispatch(
          openInfo({
            type: "error",
            message:
              "Ha ocurrido un error al procesar su order, intente nuevamente",
          }),
        );
      });
  };

  const emptyAllOrders = () => {
    dispatch(emptyOrders());
    dispatch(
      openInfo({
        type: "success",
        message: "Se han vaciado todas las órdenes",
      }),
    );
  };

  return (
    <Paper sx={{ height: "4vh" }} elevation={4} className={classes.bottom}>
      <Grid container alignItems="center" justifyContent="space-around">
        <Grid item onClick={emptyAllOrders}>
          Vaciar
        </Grid>
        <Grid item>
          <Button className={classes.primaryButton} onClick={handleConfirm}>
            Confirmar
          </Button>
        </Grid>
        <Grid item className={classes.amount}>
          ${currentAmount}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Actions;
