import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { CircleFlag } from "react-circle-flags";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import { setOpenSidebar } from "../reducers/info.js";
import { setLoading } from "../reducers/loading.js";
import { makeStyles } from "@mui/styles";
import { codeFlagsLanguage } from "../services/languages.js";

const useStyles = makeStyles(() => ({
  radio: {
    color: "#EE7B5A",
    "&$checked": {
      color: "#EE7B5A",
    },
  },
  checked: {},
  label: {
    padding: "30px;",
  },
}));

const Languages = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const languages = codeFlagsLanguage(t);

  return (
    <Grid
      container
      spacing={2}
      sx={{ height: "90vh", overflow: "hidden", overflowY: "auto" }}
    >
      <Grid item>
        <ArrowBack
          fontSize="20"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            padding: "20px",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={i18n.language}
            name="radio-buttons-group"
          >
            {languages.map(({ flag, code, language }) => (
              <React.Fragment key={code}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  key={language}
                  sx={{
                    marginLeft: "20px",
                    padding: "10px 10px 10px 10px",
                    width: "100vw",
                  }}
                  onClick={() => {
                    if (code !== i18n.language) {
                      dispatch(setLoading(true));
                      i18n.changeLanguage(code);
                      dispatch(setOpenSidebar(false));
                      navigate(-1);
                    }
                  }}
                >
                  <Grid item>
                    <Grid container alignItems="center" spacing={5}>
                      <Grid item>
                        <CircleFlag countryCode={flag} height="30" />
                      </Grid>
                      <Grid item>{language}</Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value={code}
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      id={language}
                      sx={{ marginRight: "50px" }}
                      className="click-area"
                    />
                  </Grid>
                </Grid>
                <Divider />
              </React.Fragment>
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default Languages;
