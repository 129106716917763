const FRTranslation = {
  fr: {
    translation: {
      popUp: {
        title: "Aide à l`utilisation de la Réalité Augmentée",
        text: "1. Dirigez votre caméra vers une table ou une surface claire.",
        text2:
          "2. Déplacez lentement votre téléphone portable en cercles jusqu´à ce que le dessin de la main disparaisse.",
        text3:
          "3. Utilisez un doigt pour déplacer la parabole et deux pour la faire pivoter. Si vous rapprochez la caméra de la parabole, vous la verrez plus en détail !",
      },
      buttons: {
        accept: "Accepter",
      },
      sidebar: {
        languages: {
          title: "Langues",
          es: "Espagnol",
          en: "Anglais",
          pt: "Portugais",
          it: "Italien",
          zh: "Chinois",
          ar: "Arabe",
          fr: "Français",
          he: "Hébreu",
          ru: "Russe",
          ja: "Japonais",
          de: "Allemand",
        },
      },
      index: {
        allCategories: "Voir tout",
        searchPlaceholder: "Que voulez-vous commander?",
        filter: "Filtre",
        viewMenu3D: "VOIR LE MENU 3D",
        moreInformation: "Plus d'informations",
        snackbar:
          "Faites glisser un doigt de gauche à droite sur un plat pour le traduire dans la langue du serveur.",
      },
      category: {
        product: {
          dish3d: "Plat 3D",
          outstanding: "Remarquable",
          extras: "SUPPLÉMENTS",
          dish360: "Plat 360",
          dishVideo: "Vidéo",
        },
      },
      product: {
        see360: "VOIR EN 360",
        see3D: "VOIR EN 3D",
        seeVideo: "VOIR LA VIDÉO",
        ar: "RÉALITÉ AUGMENTÉE (RA)",
        dietTypes: "Types de régime - Spécifications",
        waiterButton: "TRADUIRE PLAT AU SERVEUR",
      },
      product3D: {
        viewAR: "VOIR EN RÉALITÉ AUGMENTÉE (RA)",
        view3D: "VOIR EN 3D",
      },
      filter: {
        title: "Filtre",
        dietTypes: "Types de régime - Spécifications",
        dishes3D: "Plats 3D / 360 / Vidéo",
        clear: "Effacer",
        apply: "Appliquer",
        featuredDishes: "Produits Vedettes",
        withDiscount: "Avec remise",
      },
      error: {
        title: "Oh!",
        subtitle: "Quelque chose s'est mal passé",
        caption:
          "Assurez-vous que le QR ou le lien appartient à un établissement associé à Cartear ou réessayez.",
      },
      about: {
        daysAndSchedules: "Jours et Horaires",
        location: "Emplacement",
        coverCharge: "Droit de Couvert",
        socialNetworks: "Réseaux sociaux",
      },
      days: {
        0: "Lundi",
        1: "Mardi",
        2: "Mercredi",
        3: "Jeudi",
        4: "Vendredi",
        5: "Samedi",
        6: "Dimanche",
      },
    },
  },
};

export default FRTranslation;
