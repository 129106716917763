import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { addOrder } from "../../reducers/branch";
import { openInfo } from "../../reducers/info";

const useStyles = makeStyles(() => ({
  actions: {
    position: "fixed !important",
    bottom: 0,
    zIndex: 10000,
    width: "100vw",
    maxHeight: "3vh !important",
    boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "15px 15px 0px 0px !important",
    background: "#FAFBFD !important",
    padding: "30px 0px",
  },
  primaryButton: {
    textAlign: "center",
    padding: "5px",
    width: "40vw",
    height: "5vh",
    color: "white !important",
    background:
      "linear-gradient(163deg, #E20613 -51.13%, #EE7B5A 89.47%) !important",
    borderRadius: "50px !important",
    textTransform: "capitalize !important",
    fontWeight: "bold !important",
    marginLeft: "10px",
    marginTop: "-15px",
  },
  secondaryButton: {
    borderRadius: "20px",
    width: "40vw",
    height: "5vh",
    border: "1px solid #EE7B5A",
    marginTop: "-15px",
    color: "#FF4B55",
    padding: "10px",
  },
}));

const Actions = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { product, order, setOrder } = props;

  const canOrder = () => {
    const requiredAcc = product.accompaniments.filter(
      (acc) => acc.is_required && acc.items.length > 0,
    );
    const canAddOrder = requiredAcc.map((acc) =>
      order.items.some((item) => item.item.parent === acc.name),
    );
    return !canAddOrder.includes(false);
  };

  return (
    <Grid container justifyContent="center" className={classes.actions}>
      <Grid item className={classes.secondaryButton} xs={5}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: "-1vh" }}
        >
          <Grid item>
            <RemoveIcon
              onClick={() => {
                if (order.quantity > 1) {
                  setOrder({ ...order, quantity: order.quantity - 1 });
                }
              }}
            />
          </Grid>
          <Grid item>{order?.quantity}</Grid>
          <Grid item>
            <AddIcon
              onClick={() =>
                setOrder({ ...order, quantity: order.quantity + 1 })
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className={classes.primaryButton}
        xs={5}
        onClick={() => {
          if (canOrder()) {
            const totalAmount =
              product.price +
              order.items.reduce(
                (acc, i) => acc + i.quantity * i.item.price,
                0,
              );
            const newOrder = { ...order };
            newOrder.totalAmount = Number(
              (order.quantity * totalAmount).toFixed(2),
            );
            newOrder.totalItemAmount = Number(totalAmount.toFixed(2));
            dispatch(addOrder(newOrder));
            navigate(-1);
            dispatch(
              openInfo({
                type: "success",
                message: "Orden añadida con éxito",
              }),
            );
          } else {
            dispatch(
              openInfo({
                type: "error",
                message: "Hay items requeridos que no han sido añadidos",
              }),
            );
          }
        }}
      >
        Agregar
      </Grid>
    </Grid>
  );
};

export default Actions;
