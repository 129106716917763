import { createSlice } from "@reduxjs/toolkit";

export const loadingReducer = createSlice({
  name: "loading",
  initialState: {
    isLoading: true,
    notFound: false,
    refresh: true,
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setNotFound: (state, action) => {
      state.notFound = action.payload;
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload;
    },
  },
});

export const { setLoading, setNotFound, setRefresh } = loadingReducer.actions;

export default loadingReducer.reducer;
