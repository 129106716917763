import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Login from "./components/login/Login";
import MenuIndex from "./pages/Index";
import ShowProduct from "./pages/Show";
import Product3d from "./pages/Product3d";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import { Grid } from "@mui/material";
import Loader from "./components/utils/Loader";
import InfoSnackbar from "./components/utils/InfoSnackbar";
import InfoOrder from "./components/utils/InfoOrder";
import { getAuth } from "firebase/auth";
import { setShowInvalidMessage } from "./reducers/info";
import "./translations/i18n";
import "@google/model-viewer";
import {
  setCanActivateAR,
  setRefreshToken,
  openInfo,
  setOpenSidebar,
} from "./reducers/info";
import { setUser } from "./reducers/user";
import { setLoading } from "./reducers/loading";
import { auth } from "./index";
import box from "./images/Box.glb";
import { anonymousSignIn } from "./services/firebase";
import Filter from "./pages/Filter";
import Languages from "./pages/Languages";

const App = () => {
  const { isLoading } = useSelector((state) => state.loading);
  const { branch } = useSelector((state) => state.branch);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { order, info } = useSelector((state) => state.info);
  const dispatch = useDispatch();


  axios.defaults.baseURL = process.env.REACT_APP_REQUEST_BASE_URL;
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error?.response?.status === 401) {
        try {
          anonymousSignIn(getAuth());
          auth.onIdTokenChanged((firebaseUser) => {
            if (firebaseUser) {
              firebaseUser
                .getIdToken(true)
                .then((accessToken) => {
                  dispatch(setRefreshToken(true));
                  dispatch(setUser({ accessToken, uid: firebaseUser.uid }));
                })
                .catch((_error) => {
                  dispatch(
                    openInfo({ type: "error", message: "Error de conexión" }),
                  );
                  dispatch(setLoading(false));
                });
            }
          });
        } catch {
          dispatch(openInfo({ type: "error", message: "Error de conexión" }));
          dispatch(setLoading(false));
        }
      } else if (error?.response?.status === 404) {
        dispatch(setShowInvalidMessage(true));
      }
    },
  );

  axios.interceptors.request.use(
    (config) => {
      dispatch(setLoading(true));
      const token = getAuth().currentUser?.accessToken || user.accessToken;
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        ApiKey: process.env.REACT_APP_BACKOFFICE_API_KEY,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  useEffect(() => {
    dispatch(setOpenSidebar(false));
    const modelViewer = document.querySelector("model-viewer");
    if (modelViewer) {
      modelViewer.addEventListener("load", function () {
        dispatch(setCanActivateAR(modelViewer.canActivateAR));
        modelViewer.remove();
      });
    }
  }, []);

  return (
    <Grid
      container
      sx={{
        background: "transparent !important",
        height: "100%",
      }}
    >
      <model-viewer
        id="support-model-viewer"
        src={box}
        style={{ visibility: "hidden", width: 1, height: 1 }}
        ar
        ar-modes="webxr scene-viewer quick-look"
      />
      {order.open && <InfoOrder order={order} branch={branch} />}
      {isLoading && <Loader />}
      {info.open && <InfoSnackbar type={info.type} message={info.message} />}
      <Routes>
        <Route path="/:branchName/menu" element={<Login />}>
          <Route exact path="/:branchName/menu" element={<MenuIndex />} />
          <Route
            exact
            path="/:branchName/menu/languages"
            element={<Languages />}
          />
          <Route exact path="/:branchName/menu/about" element={<About />} />
          <Route exact path="/:branchName/menu/:id" element={<ShowProduct />} />
          <Route exact path="/:branchName/menu/filters" element={<Filter />} />
          <Route
            exact
            path="/:branchName/menu/:id/product_viewer"
            element={<Product3d />}
          />
        </Route>
        <Route path="/:branchName/product" element={<Login />}>
          <Route
            exact
            path="/:branchName/product/:id/product_viewer"
            element={<Product3d />}
          />
          <Route
            exact
            path="/:branchName/product/:id/product_viewer/languages"
            element={<Languages />}
          />
          <Route
            exact
            path="/:branchName/product/:id/product_viewer/about"
            element={<About />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Grid>
  );
};

export default App;
