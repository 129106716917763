import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userReducer from "./reducers/user";
import filterReducer from "./reducers/filter";
import branchReducer from "./reducers/branch";
import dishesReducer from "./reducers/dishes";
import loadingReducer from "./reducers/loading";
import infoReducer from "./reducers/info";
import persistedInfoReducer from "./reducers/persisted";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const rootReducer = combineReducers({
  user: userReducer,
  filter: filterReducer,
  branch: branchReducer,
  dishes: dishesReducer,
  loading: loadingReducer,
  info: infoReducer,
  persisted: persistedInfoReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["loading", "branch", "dishes", "info", "filter"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export let persistor = persistStore(store);
