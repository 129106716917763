import { createSlice } from '@reduxjs/toolkit';

export const branchReducer = createSlice({
  name: 'branch',
  initialState: {
    branch: {
      id: null,
      tableId: null,
      restaurantId: null,
      allergens: [],
      categories: [],
      orders: [],
      orderedOrders: [],
    }
  },
  reducers: {
    setBranch: (state, action) => {
      state.branch = action.payload
    },
    setBranchCategories: (state, action) => {
      state.branch.categories = action.payload
    },
    setBranchAllergens: (state, action) => {
      state.branch.allergens = action.payload
    },
    addOrder: (state, action) => {
      const index = state.branch.orders.findIndex(order => order.id === action.payload.id);
      if(index !== -1) {
        const newOrders = [...state.branch.orders]
        newOrders[index] = action.payload
        state.branch.orders = newOrders
      } else {
        state.branch.orders = [...state.branch.orders, action.payload]
      }
    },
    removeOrder: (state, action) => {
      const newOrders = state.branch.orders.filter(order => order.id !== action.payload.id);
      state.branch.orders = newOrders;
    },
    emptyOrders: (state, _action) => {
      state.branch.orders = [];
    },
    ordersToOrderedOrders: (state, _action) => {
      state.branch.orderedOrders = [...state.branch.orderedOrders, ...state.branch.orders];
      state.branch.orders = [];
    }
  }
})

export const {
  setBranch, setBranchCategories, setBranchAllergens, addOrder, removeOrder, ordersToOrderedOrders, emptyOrders, addDish,
} = branchReducer.actions;

export default branchReducer.reducer;
